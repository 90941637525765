@media (max-width: 1199px) {
  #block-bean-block-video-on-main,
  #block-bean-block-video-on-main-en {
    width: 920px;
    height: 280px;
    margin: 0 auto;
    .field-name-field-text-for-video {
      top: 70px;
      h5 {
        font-size: 42px;
        margin: 0 0 20px 40px;
      }
      span {
        font-size: 36px;
        margin: 0 0 0 40px;
      }
    }
    .field-name-field-video {
      top: 190px;
      left: 40px;
    }
  }
  #block-views-banner-block #flexslider-1 {
    width: 540px;
    height: 410px;
    .slides .views-field-nothing {
      width: 250px;
      padding: 25px 15px 0;
    }
  }
  #block-views-map-on-main-block {
    width: 370px;
    .views-field-nothing {
      padding: 0px 5px 0;
      .field-content .banner-description {
        font-size: 12px;
        line-height: 18px;
        width: auto;
        margin-bottom: 0;
      }
    }
  }
  #block-block-26,
  #block-block-28 {
    width: 370px;
    height: 200px;
    margin-left: 10px;
    margin-top: -2px;
    .block-catalog-image {
      width: 117px;
      height: 182px;
      margin-right: 5px;
      .catalog-main-link {
        .first-catalog {
          font-size: 16px;
          line-height: 22px;
          margin: 40px 5px 5px 5px;
        }
        .description-catalog {
          font-size: 12px;
          line-height: 16px;
          margin: 5px;
        }
      }
    }
  }
  #block-views-recent-works-block-1,
  #block-views-recent-works-block {
    width: 660px;
  }
  .showworks {
    ul .works-block-row:nth-child(1),
    ul .works-block-row:nth-child(2) {
      width: 328px;
    }
    .views-row-1,
    .views-row-6 {
      width: 322px;
    }
    .views-row-2,
    .views-row-3,
    .views-row-4,
    .views-row-5 {
      width: 156px;
    }
    .views-row-2,
    .views-row-4 {
      margin-right: 5px;
    }
  }
  #block-system-main {
    .showworks {
      ul {
        li {
          width: 448px;
          margin-right: 20px;
          margin-bottom: 20px;
          .portfolio-item {
            .item-description {
              span {

              }
              h5 {

              }
            }
          }
        }
      }
    }
  }
  #block-system-main .showworks ul li:nth-child(3n) {
    margin-right: 20px;
  }
  #block-system-main .showworks ul li:nth-child(2n) {
    margin-right: 0;
  }
  #block-block-31,
  #block-block-32 {
    width: 251px;
    margin-left: 9px;
    .link-to-exhibitions {
      width: 251px;
      height: 354px;
      margin-top: 14px;
    }
  }

  #block-block-29,
  #block-block-30 {
    .link-to-works {
      margin-right: 170px;
    }
  }
  #clients-logo-wrapper {
    float: none;
    width: auto;
    margin: 0 auto;
  }
  .container .thirteen.columns {

  }
}

@media (max-width: 959px) {
  #block-bean-block-video-on-main,
  #block-bean-block-video-on-main-en {
    width: 748px;
    height: 351px;
    margin: 0 auto;
    background: url(/sites/all/themes/astrum/images/invest-main-photo-768.jpg) no-repeat;
    .field-items {
      img {
        width: 768px;
        height: 351px;
        display: none;
      }
    }
  }
  #navigation #block-superfish-1 #superfish-1 {
    li {
      a {
        padding: 30px 22px 30px 22px;
      }
    }
    li:nth-child(1) a {
      padding: 30px 22px 30px 22px;
    }
  }
  #search-form {
    margin: 24px 0 0 0;
  }
  #block-views-banner-block #flexslider-1 {
    width: 748px;
  }
  #block-views-map-on-main-block {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
    .views-field-nothing {
      width: 250px;
      height: 100%;
      top: 0;
      right: 0;
      padding: 15px 15px 0;
      .field-content .banner-description {
        width: auto;
      }
    }
  }
  #block-block-26, #block-block-28 {
    width: auto;
    height: 190px;
    margin-left: 0;
    margin-top: 10px;
    .block-catalog-image {
      width: 242px;
      height: 180px;
    }
  }
  #block-views-recent-works-block-1,
  #block-views-recent-works-block {
    width: 748px;
  }
  .showworks {
    ul .works-block-row:nth-child(1),
    ul .works-block-row:nth-child(2) {
      width: 372px;
    }
    .views-row-1,
    .views-row-6 {
      width: 367px;
    }
    .views-row-2,
    .views-row-3,
    .views-row-4,
    .views-row-5 {
      width: 178px;
    }
  }
  #block-system-main .showworks ul li {
    width: 361px;
  }
  #block-block-31 {
    width: 746px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 746px;
    }
  }
  #block-block-32 {
    width: 746px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 746px;
    }
  }
  #block-block-29,
  #block-block-30 {
    width: 720px;
    margin-top: 10px;
  }

  .events-header {
    .events-header-date {
      width: 90px;
    }
    .events-header-text {
      margin-left: 63px;
    }
    .views-exposed-form .views-exposed-widgets {
      .views-exposed-widget {
        padding-top: 2px;
        padding-right: 0;
        margin-left: 15px;
        #edit-field-page-category-tid {
          .form-type-checkbox {
            .option {
              margin-right: 5px;
            }
          }
        }
        .form-submit {
        }
      }
      .views-submit-button {
      }
    }
  }
  .events-list {
    ul {
      li {
        .views-field-field-event-date {
          width: 80px;
        }
        .views-field-title {
          margin-left: 150px;
        }
        .views-field-field-venue {
          margin-left: 150px;
        }
        .views-field-body {
          margin-left: 150px;
        }
        .views-field-field-organizer {
          margin-left: 150px;
        }
      }
    }
  }



}

@media (max-width: 767px) {
  #block-bean-block-video-on-main,
  #block-bean-block-video-on-main-en {
    width: 420px;
    background: url(/sites/all/themes/astrum/images/invest-main-photo-420.jpg) no-repeat;
    .field-items {
      img {
        width: 420px;
        height: 351px;
        display: none;
      }
    }
    .field-name-field-text-for-video {
      top: 95px;
      h5 {
        font-size: 42px;
        margin: 0 0 16px 40px;
      }
      span {
        font-size: 28px;
        margin: 0 0 0 40px;
      }
    }
    .field-name-field-video {
      top: 205px;
      left: 40px;
    }
  }
  #block-views-banner-block #flexslider-1 {
    width: auto;
    height: 320px;
    .slides .views-field-nothing {
      width: 180px;
      .banner-headline h2 {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .banner-description {
        display: none;
      }
    }
    .flex-control-nav {
      right: 22px;
    }
  }
  #block-views-map-on-main-block .views-field-nothing {
    width: 180px;
    .field-content .banner-description {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  #block-block-26,
  #block-block-28 {
    .block-catalog-image {
      width: 134px;
    }
  }

  #block-views-recent-works-block-1,
  #block-views-recent-works-block {
    width: 420px;
  }
  .showworks {
    ul .works-block-row:nth-child(1),
    ul .works-block-row:nth-child(2) {
      width: 420px;
    }
    .views-row-1,
    .views-row-4 {
      width: 420px;
    }
    .views-row-2,
    .views-row-3,
    .views-row-6,
    .views-row-5 {
      width: 205px;
    }
    .views-row-2,
    .views-row-5 {
      margin-right: 5px;
    }
    .views-row-4 {
      margin-right: 0;
    }
  }
  #block-system-main .showworks ul li {
    width: 100%;
    margin-right: 0;
  }
  #block-system-main .showworks ul li:nth-child(3n) {
    margin-right: 20px;
  }
  #block-block-31 {
    width: 420px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 420px;
      height: 500px;
    }
  }
  #block-block-32 {
    width: 420px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 420px;
      height: 500px;
    }
  }
  #block-block-29,
  #block-block-30 {
    width: auto;
    .link-to-works {
      margin-right: 30px;
    }
  }
  #clients-logo-wrapper {
    width: auto;
  }
  a.menu-trigger,
  .search-trigger {
    padding: 25px 30px 10px 30px;
  }
  #search-form {
    display: none;
  }
  #jPanelMenu-menu {
    li {
      display: inline-block;
      width: 100%;
      a {
        color: #fff;
        padding: 12px 10px 12px 15px;
        font-size: 18px;
      }
      a:hover {
        border-bottom: 2px solid #f0f0f0;
      }
      ul {
        li a {
          padding: 12px 10px 12px 30px;
          font-size: 16px;
        }
        ul li a {
          padding: 12px 10px 12px 60px;
          font-size: 14px;
        }
      }
    }
  }

  #jPanelMenu-menu li:nth-child(1) {
    ul {
      li a {
        background-color: #2d6ba6;
      }
      ul li a {
        background-color: #2d6ba6;
      }
    }
    a {
      background-color: #2d6ba6;
    }
  }
  #jPanelMenu-menu li:nth-child(2) {
    ul {
      li a {
        background-color: #7da004;
      }
      ul li a {
        background-color: #7da004;
      }
    }
    a {
      background-color: #7da004;
    }
  }
  #jPanelMenu-menu li:nth-child(3) {
    ul {
      li a {
        background-color: #e3a921;
      }
      ul li a {
        background-color: #e3a921;
      }
    }
    a {
      background-color: #e3a921;
    }
  }
  .thirteen #block-views-social-media-icons-block {
    display: none;
  }
  #navigation #block-locale-language .language-switcher-locale-url {
    margin-top: 0px;
    margin-right: 20px;
    li {
      width: 24px;
      height: 20px;
      overflow: hidden;
      text-align: center;
      .language-icon .language-link img {
        margin-top: 3px;
      }
    }
    li:hover .language-icon .language-link img {
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.4);
    }
    li.active {
      .language-icon .language-link img {
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .three.columns {
    margin-bottom: 4px;
  }

  .events-header {
    .events-header-date {
      width: 90px;
    }
    .events-header-text {
      margin-left: 63px;
    }
    .views-exposed-form .views-exposed-widgets {
      .views-exposed-widget {
        padding-top: 2px;
        padding-right: 0;
        margin-left: 0;
        #edit-field-page-category-tid {
          .form-type-checkbox {
            .option {
              margin-right: 5px;
            }
          }
        }
        .form-submit {
        }
      }
      .views-submit-button {
      }
    }
  }
  .events-list {
    ul {
      li {
        .views-field-field-event-date {
          width: 80px;
        }
        .views-field-title {
          margin-left: 150px;
        }
        .views-field-field-venue {
          margin-left: 150px;
        }
        .views-field-body {
          margin-left: 150px;
        }
        .views-field-field-organizer {
          margin-left: 150px;
        }
      }
    }
  }
  .events-header .views-exposed-form .views-exposed-widgets .views-exposed-widget .views-widget {
    border: 1px solid #a0a0a0;
  }

}

@media (max-width: 480px) {
  #block-bean-block-video-on-main,
  #block-bean-block-video-on-main-en {
    width: 300px;
    height: 260px;
    .field-name-field-text-for-video {
      top: 55px;
      h5 {
        font-size: 35px;
        margin: 0 0 16px 30px;
      }
      span {
        font-size: 28px;
        margin: 0 0 0 30px;
      }
    }
    .field-name-field-video {
      top: 155px;
      left: 30px;
    }
  }
  #block-views-banner-block #flexslider-1 {
    height: 228px;
    .slides .views-field-nothing {
      width: 150px;
      .banner-headline h2 {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .flex-control-nav {
      right: 13px;
      li {
        width: 20px;
        height: 20px;
        margin: 0 4px;
        a {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  #block-views-map-on-main-block .views-field-nothing {
    width: 150px;
  }
  #block-block-26,
  #block-block-28 {
    .block-catalog-image {
      width: 300px;
      height: 125px;
      margin-right: 0px;
      .catalog-main-link {
        .first-catalog {
          margin: 40px 10px 5px 15px;
        }
        .description-catalog {
          margin: 5px 10px 5px 15px;
        }
      }
    }
  }
  .showworks {
    .views-row-1,
    .views-row-2,
    .views-row-3,
    .views-row-4,
    .views-row-5,
    .views-row-6 {
      width: 300px;
      margin-right: 0;
    }
  }
  #block-system-main .showworks ul li {
    margin-bottom: 15px;
  }
  #block-system-main .showworks ul li:hover .portfolio-item .item-description span {
    display: none;
  }
  #block-system-main .showworks ul li .portfolio-item .item-description {
    padding: 15px 20px 5px 20px;
    h5 {
      font-size: 16px;
    }
  }
  #block-block-31 {
    width: 300px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 300px;
      height: 393px;
      margin-top: 0;
    }
  }
  #block-block-32 {
    width: 300px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 300px;
      height: 393px;
      margin-top: 0;
    }
  }

  .events-header {
    .events-header-date {
      width: 90px;
    }
    .events-header-text {
      margin-left: 63px;
    }
    .views-exposed-form .views-exposed-widgets {
      .views-exposed-widget {
        padding-top: 2px;
        padding-right: 0;
        margin-left: 0;
        #edit-field-page-category-tid {
          .form-type-checkbox {
            .option {
              margin-right: 5px;
            }
          }
        }
        .form-submit {
        }
      }
      .views-submit-button {
      }
    }
  }
  .events-list {
    ul {
      li {
        .views-field-field-event-date {
          width: 80px;
        }
        .views-field-title {
          margin-left: 105px;
        }
        .views-field-field-venue {
          margin-left: 105px;
        }
        .views-field-body {
          margin-left: 105px;
        }
        .views-field-field-organizer {
          margin-left: 105px;
        }
      }
    }
  }

}

@media (max-width: 320px) {
  #block-bean-block-video-on-main,
  #block-bean-block-video-on-main-en {
    width: 270px;
    height: 260px;
    .field-name-field-text-for-video span {
      font-size: 26px;
    }
  }
  #block-views-banner-block #flexslider-1 {
    height: 205px;
    .slides .views-field-nothing {
      padding: 20px 10px 0;
    }
  }
  #block-views-map-on-main-block .views-field-nothing {
    padding: 10px 10px 0;
    .field-content .banner-description {
      margin-bottom: 5px;
    }
  }
  #block-block-26,
  #block-block-28 {
    .block-catalog-image {
      width: 270px;
    }
  }
  .showworks {
    .views-row-1,
    .views-row-2,
    .views-row-3,
    .views-row-4,
    .views-row-5,
    .views-row-6 {
      width: 270px;
    }
  }
  .showworks ul .works-block-row:nth-child(1),
  .showworks ul .works-block-row:nth-child(2) {
    width: 270px;
  }
  #block-views-recent-works-block-1,
  #block-views-recent-works-block {
    width: 270px;
  }
  #block-block-31,
  #block-block-32 {
    width: 270px;
  }
  #block-block-31 {
    width: 270px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 270px;
      height: 354px;
      margin-top: 0;
    }
  }
  #block-block-32 {
    width: 270px;
    margin-left: 0;
    .link-to-exhibitions {
      width: 270px;
      height: 354px;
      margin-top: 0;
    }
  }
  .events-list {
    ul {
      li {
        .views-field-field-event-date {
          width: 80px;
        }
        .views-field-title {
          margin-left: 95px;
        }
        .views-field-field-venue {
          margin-left: 95px;
        }
        .views-field-body {
          margin-left: 95px;
        }
        .views-field-field-organizer {
          margin-left: 95px;
        }
      }
    }
  }
}