body {
  @include line-height (16);
  font-family: "myriad-pro", sans-serif;
  background: #ffffff;
  color: $grey-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container:after,
.header:after,
.body-container:after,
.footer:after,
.copyright:after,
.node-type-eds-properties .main-content:after,
.front-features-one .region:after,
.front-features-two .region:after,
.front-features-three .region:after,
.front-features-four .region:after,
.banner-internal #page-title:after,
.navigation-top .region:after,
.banner .views-field-nothing:after,
body.page-eds-report .report-header-region:after,
body.page-eds-report .comparison-remove:after,
body.page-eds-report .main .section:after {
  @include clearfix();
}

#{$a-tags} {
  text-decoration: none;
  color: $link;
  outline: none !important;
}

#{$a-tags-hover} {
  color: $black;
  outline: none !important;
}

img {
  display: block;
  max-width: 100%;
  height: auto !important;
}

.logo {
  float: left;
  margin: 8px 0 0 !important;
  &.logo-text {
    a {
      color: #000000;
      font: 700 33px 'Helvetica Neue', 'Helvetica', sans-serif;
      line-height: 33px;
      letter-spacing: 6.6px;
      text-transform: uppercase;
    }
  }
}

.main {
  h1, h2, h3, h4, h5, h6 {
    padding-top: 15px;
    &:first-child {
      padding-top: 0;
    }
  }
  p:first-child {
    margin-top: 0;
  }
}

h4,
#page-title {
  @include font-size(22px);
  text-transform: uppercase;
}

#page-title {
  margin-bottom: 10px;
}

.site-container {
  position: relative;
}

.padding_top {
  padding-top: 159px;
}

// EDsuite v3 overwrites
ul.links.inline,
#block-system-help {
  font-size: 0.889em;
  margin-top: 1.8em;
  display: block;
  li,
  span {
    display: inline-block;
    margin: 0 1em 0 0;
    padding: 0 0.5em 0 0;
    width: auto !important;
    line-height: 100%;
    img {
      float: left;
      margin-bottom: -2px;
      margin-right: 3px;
    }
  }
  p,
  .statistics_counter {
    display: none;
  }
}

div.messages {
  margin-bottom: 25px;
}

// Add to report link
div.report_change {
  float: none;
  span {
    top: -70px;
  }
  a {
    color: $link;
    cursor: pointer;
    &:hover,
    &:visited {
      background: $white;
      border-color: $black;
      color: $black;
    }
  }
}

.logged-in {
  div.report_change span {
    top: -100px;
  }
}

// Overwrite Drupal's Default tabs
ul.tabs {
  border-bottom: 0;
  white-space: normal;
  line-height: 1.6em;
  padding: 0;
  margin: 0 0 20px 0;

  li {
    a {
      padding: 3px 1em;
      border-style: solid;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      white-space: nowrap;
      margin-right: .1em;
      &:hover {
        border-bottom-color: #ccc;
      }
    }
    &.active a {
      border-bottom: 1px solid #bbb;
    }
  }
}

body #block-views-internal-side-boxes-block .views-row .views-field-field-title-actual-int {
  /* position: absolute; */
  top: 0;
  display: table;
  width: 100.4%;
  height: 270px;
}

@-moz-document url-prefix() {
  body #block-views-internal-side-boxes-block .views-row .views-field-field-title-actual-int {
    width: 100%;
  }
}

body #block-views-internal-side-boxes-block .views-row .views-field-field-title-actual-int .field-content {
  display: table-cell;
  vertical-align: bottom;
  text-align: left;
  padding-left: 16px;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  line-height: 31px;
  text-shadow: 1px 1px #000;
}

body #block-views-internal-side-boxes-block .views-row .views-field-field-description-box-int {
  min-height: 180px;
  padding: 25px;
  text-align: left;
  background: #d4d4d4;
}

body #block-views-internal-side-boxes-block .views-row .views-field-field-link-box-int a {
  display: block;
  background: $link;
  width: 100%;
  padding: 5px 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  text-align: right;
}

body #block-views-internal-side-boxes-block .views-row .views-field-field-link-box-int a:hover {
  background: #000;
}