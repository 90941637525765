// Heights and widths for the major containers.

//.container,
//.header,
//.body-container,
//.footer,
//.copyright,
//.node-type-eds-properties .main-content,
//.front-features-one .region,
//.front-features-two .region,
//.front-features-three .region,
//.front-features-four .region,
//.banner-internal #page-title,
//.navigation-top .region,
//body.page-eds-report .report-header-region,
//body.page-eds-report .comparison-remove,
//body.page-eds-report .main .section {
//  margin: auto;
//  max-width: 1100px;
//  padding-left: 20px;
//  padding-right: 20px;
//}
//
//.container {
//  @include clearfix;
//  margin: auto;
//  max-width: 1100px;
//  padding-left: 20px;
//  padding-right: 20px;
//}

.header {
  @extend .container;
  height: 130px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

.body-container {
  @extend .container;
  margin-top: 40px;
  margin-bottom: 60px;
  position: relative;
}

.footer {
  @extend .container;
  padding-top: 60px;
  padding-bottom: 60px;
}

.copyright {
  @extend .container;
}

.main {
  min-height: 720px;
}

.banner-internal-active .main {
  padding-top: 60px;
}

.sidebar-first {
  .sidebar-first {
    float: left;
    width: 24%;
  }
  .main {
    float: right;
    width: 70%;
  }
}

.front .body-container {
  max-width: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
}

.col-2-bk,
.col-3-bk,
.col-4-bk {
  padding-right: 20px;
  padding-left: 20px;
}

.col-2-bk .block,
.col-3-bk .block,
.col-4-bk .block {
  float: left;
  padding: 0 10px;
}

.col-2-bk .block {
  width: 50%;
}

.col-3-bk .block {
  width: 33.333%;
}

.col-4-bk .block {
  width: 25%;
}

.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.banner .views-field-nothing {
  max-width: 100%;
}

.banner .views-field-nothing-1 {
  display: none;
  position: absolute;
  padding-top: 45px;
  top: 0;
}