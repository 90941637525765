
#block-views-why-cherkasy-block {
  .isotope-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 50px;

    li {
      margin-right: 15px;
      margin-bottom: 15px;

      a {
        font-size: 16px;
        color: #13b8e5;

        .views-field-field-term {
          padding: 5px 15px;
          border-radius: 5px;
          border: 1px solid #13b8e5;
        }

        &:hover {
          color: #001549;
          .views-field-field-term {
            border-color: #001549;
          }
        }
      }

      .selected {

        .views-field-field-term {
          border-color: #001549;
          background-color: #001549 !important;
        }
      }
    }

    .first {
      a {
        padding: 5px 15px;
        border-radius: 5px;
        border: 1px solid #13b8e5;
        color: #13b8e5;
      }

      .selected {
        border-color: #001549;
        background-color: #001549 !important;
      }
    }
  }

  .isotope-container {

    .isotope-element {
      width: 20%;
      min-height: 250px;
      margin-right: 15px;
      margin-bottom: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #7da004;

      &:hover {
        cursor: pointer;
        background-color: #566E03;
        transition: background-color 0.5s;
      }

      .views-field-field-item-name {
        padding: 10px 7px;

        .field-content {

          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          span,
          strong {
            color: #ffffff;
          }
        }
      }

      @media  (max-width: 1024px) {
        width: 30%;
      }

      @media  (max-width: 800px) {
        width: 30%;
      }

      @media  (max-width: 736px) {
        width: 50%;
      }

      @media  (max-width: 415px) {
        width: 100%;
      }

      .modal-title {
        color: #ffffff;
        text-align: center;
      }

      .views-field-field-item-name {
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        font-weight: 700;

        p {
          line-height: 30px;
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
    }
    .modal-dialog {
      display: none;
    }
  }

  .item-list {

    .yellow {
      background-color: #e3a921;

      &:hover {
        background-color: #BA8917;
        transition: background-color 0.5s;
      }
    }

    .blue {
      background-color: #2d6ba6;

      &:hover {
        background-color: #22517E;
        transition: background-color 0.5s;
      }
    }

    .green {
      background-color: #7da004;

      &:hover {
        background-color: #566E03;
        transition: background-color 0.5s;
      }
    }
  }
}


.ui-widget-overlay {
  z-index: 410;
}

.ui-dialog {
  z-index: 420;
  border: none;
  padding: 0;

  //Using important to remove inline modal window styles

  @media  (max-width: 800px) {
    width: 600px !important;
  }

  @media  (max-width: 568px) {
    width: 540px !important;
  }

  @media  (max-width: 415px) {
    width: 400px !important;
  }

  @media  (max-width: 375px) {
    width: 350px !important;
  }

  @media  (max-width: 320px) {
    width: 300px !important;
  }

  .ui-button-text {
    outline: none;
  }

  .ui-dialog-titlebar {
    background: none;
    border: none;
    position: absolute;
    width: 100%;
    z-index: 5;
  }

  .ui-dialog-titlebar-close {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    outline: none;
    top: 10px;
    right: 10px;
  }

  .ui-dialog-titlebar-close:before {
    background: #17191e;
    content: '';
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    height: 3px;
    width: 24px;
  }

  .modal-dialog {
    text-align: center;
    font-size: 54px;
    line-height: 75px;
    padding: 0;

    p {
      line-height: 75px;
      margin: 0;
    }

    .digits {
      font-size: 75px;
    }
  }
}


.ui-dialog .ui-dialog-titlebar-close:after {
  background: #17191e;
  content: '';
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  height: 3px;
  width: 24px;
}

.ui-dialog .ui-dialog-titlebar-close:before {
  transform: rotate(45deg);
}

.ui-dialog .ui-dialog-titlebar-close:after {
  transform: rotate(-45deg);
}

.ui-dialog .ui-icon-closethick {
  display: none;
}