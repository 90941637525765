// Styles for investments and support views

.view-export-support-view,
.view-investments-support-view {

  .views-row {
    padding-bottom: 20px;
    margin-bottom: 50px;
    border-bottom: 1px solid #d4d4d4;

    &:last-child {
      border-bottom: none;
    }
  }

  .support-item-title {
    text-align: center;
  }

  .support-info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #d4d4d4;
    padding-top: 20px;

    @media only screen and (max-width: 767px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .support-item-img {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
    }

    .support-item-info {
      margin-left: 10%;

      @media only screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
}