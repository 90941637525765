@media only screen and (min-width: 1600px) {
  .node-type-eds-properties div#image-Container a {
    display: block;
    height: 500px;
    width: 100%;
  }
  .node-type-eds-properties div#image-Container img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 1440px) {
  .banner .views-field-field-banner-image {
    width: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    margin-left: 0;
    display: block;
  }
  .banner .views-field-field-banner-image img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 1160px) {
  #block-views-hover-reveal-block .views-row {
    width: 50%;
  }
  body.front #block-views-hover-reveal-block .views-row-1 {
    width: 100%;
  }

  body.front .front-features-one {
    padding: 60px 30px;
  }

  body.front .front-features-one #block-views-numbers-block .view-content .views-row {
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    &:nth-child(even) {
      border: none;
    }
    &:nth-child(n+3) {
      margin-top: 25px;
    }
  }
}

@media only screen and (max-width: 1090px) {
  .front-features-four .block-content-container .content .view-content .views-row {
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    &:nth-child(n+3) {
      margin-top: 25px;
    }
  }
}

@media only screen and (min-width: 1060px) {
  // Sticky header styles
  #custom-report {
    @include smooth-transition(.3s, height);
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    z-index: 325; // Magic number
    overflow: hidden;
  }
  .site-container {
    @include smooth-transition(.3s);
    padding-top: 130px;
    .header-container {
      @include smooth-transition(.3s);
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 321; // Magic number(taken from prev code version)
    }
    .header-container * {
      @include smooth-transition(.3s);
    }
  }
  // Sticky header styles when logged in
  .logged-in {
    .site-container {
      .header-container {
        top: 20px;
      }
    }
  }

  // Sticky header styles WITH custom-report block active
  .with-report {
    #custom-report {
      height: 54px;
    }
    .site-container {
      padding-top: 184px;
      .header-container {
        position: fixed;
        top: 54px;
      }
    }
  }
  // Sticky header styles when logged in with custom-report block active
  .logged-in {
    &.with-report {
      #custom-report {
        top: 64px;
      }
      .site-container {
        .header-container {
          top: 118px;
        }
      }
    }
  }

  // Sticky header when scrolled
  .sticky-header {
    .site-container {
      padding-top: 99px;
      .header-container {
        -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
      }
      .logo {
        overflow: hidden;
        height: 57px;
        img {
          height: 46px !important;
        }
      }
      .header {
        padding: 12px 20px;
        height: auto;
      }
      .navigation-main-1 {
        top: 35px;
      }
      .navigation-top,
      #block-views-social-media-icons-block,
      #block-search-form {
        display: none;
      }
    }
    // Sticky header when scrolled with custom-report block active
    &.with-report {
      .site-container {
        padding-top: 156px;
      }
    }
  }

  .copyright .block-menu-block {
    display: none;
  }
}

@media only screen and (max-width: 1060px) {
  .navigation-main-1,
  #block-search-form,
  .header #block-views-social-media-icons-block {
    display: none;
  }

  .copyright .block-menu-block {
    display: block;
  }

  .header {
    height: auto;
    text-align: center;
  }

  .logo {
    float: none;
    margin: auto;
    display: inline-block;
  }

  body.not-front .site-container .banner-internal {
    margin-top: 0;
  }

  /* hide save report */
  body #custom-report #save-and-report-container {
    display: none !important;
  }
  body .site-container .main .report_change {
    display: none;
  }

}

@media only screen and (max-width: 1000px) {
  body #block-views-internal-side-boxes-block .views-row .views-field-field-title-actual-int .field-content {
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media only screen and (max-width: 820px) {
  .banner .views-field-nothing .banner-text {
    width: 100%;
    padding: 10px 80px 20px 80px;
  }
  .banner .views-field-nothing .Center_Top,
  .banner .views-field-nothing .Center_Middle,
  .banner .views-field-nothing .Center_Bottom {
    left: 0;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .col-2-bk,
  .col-3-bk,
  .col-4-bk {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 45px;
  }
  .col-2-bk .block,
  .col-3-bk .block,
  .col-4-bk .block {
    float: none;
    padding: 0;
    width: 100%;
  }

  #block-views-news-block-1,
  #block-views-blog-block-3 {
    padding-right: 0;
    padding-left: 0;
  }

  .front-features-two .block,
  .front-features-two .region {
    display: block !important;
  }
  .copyright-container {
    height: auto;
  }
  .group-jobs-company-info,
  .group-jobs-job-content {
    float: none;
    width: 100%;
  }
  #webform-client-form-11 {
    .form-item {
      input {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner .views-field-field-banner-image {
    width: 1240px;
    height: 440px;
    margin-left: -620px;
  }
  .banner .views-field-field-banner-image img {
    width: 1240px;
    height: 440px;
  }
  .banner .views-field-nothing {
    height: 440px;
  }
  .banner .views-field-nothing .banner-headline {
    font-size: 32px;
    font-size: 2rem;
    line-height: 16px;
    line-height: 2rem;
    margin-bottom: 10px;
  }
  .banner .views-field-nothing .banner-description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 12px;
    line-height: 1.5rem;
    margin-bottom: 15px;
  }
  .banner .views-field-nothing .Center_Middle {
    top: 130px;
  }

  .sidebar-first .sidebar-first {
    float: none;
    width: 100%;
    display: none;
  }
  .sidebar-first .main {
    float: none;
    width: 100%;
  }

  .custome-report,
  .report_change {
    display: none;
  }

  .banner-internal-active .main {
    padding-top: 0;
  }
}

@media only screen and (max-width: 640px) {
  .navigation-top {
    display: none;
  }
}

@media only screen and (max-width: 620px) {
  body.front .front-features-one #block-views-numbers-block .view-content .views-row {
    border: none;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    margin-top: 25px;
    &.views-row-first {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 570px) {
  .banner .views-field-nothing .banner-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  .banner .views-field-nothing .banner-headline {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 16px;
    line-height: 2rem;
    margin-bottom: 10px;
  }
  .banner .views-field-nothing .banner-description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 12px;
    line-height: 1.5rem;
    margin-bottom: 15px;
  }
  .banner .views-field-nothing .Center_Middle {
    top: 120px;
  }

  .flex-direction-nav {
    display: none;
  }

  #block-views-hover-reveal-block .views-row {
    width: 100%;
  }

  div#block-views-staff-members-block .views-field-field-staff-picture,
  div#block-views-staff-members-block .views-field-nothing {
    float: none;
    width: 100%;
    text-align: center;
  }
  div#block-views-staff-members-block .views-field-field-staff-picture {
    margin-bottom: 20px;
  }
  div#block-views-staff-members-block .views-field-field-staff-picture img {
    margin: auto;
  }
  div#block-views-staff-members-block .staff-icons {
    overflow: hidden;
    display: inline-block;
  }

  div#block-views-board-members-block .views-row {
    float: none;
    padding-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
  div#block-views-board-members-block .views-row img {
    margin: auto;
  }

  body.front #block-views-hover-reveal-block .hover-reveal-headline {
    font-size: 15px;
  }

  body.front #block-views-hover-reveal-block .views-row {
    display: none;
  }
  body.front #block-views-hover-reveal-block .views-row-1 {
    display: block;
  }

  body.front .body-container .main .front-features-one {
    padding: 60px 5px !important;
  }
}

@media only screen and (max-width: 550px) {
  .front-features-four .block-content-container .content .view-content .views-row {
    -ms-flex: 1 0 100%;
    flex: 1 0 50%;
    margin-top: 25px;
    &.views-row-first {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 480px) {
  #block-views-exp-jobs-page {
    width: 100%;
    .content,
    input[type="text"] {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 375px) {
  .banner .views-field-nothing .Center_Middle {
    top: 90px;
  }
}

@media only screen and (max-width: 320px) {
  .banner .views-field-nothing .Center_Middle {
    top: 60px;
  }
}