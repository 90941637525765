.node-type-news {

  .rrssb-item-list {
    padding-bottom: 10px;
  }

  .field-name-field-news-image {
    margin-bottom: 20px;
  }

  .news-title {
    padding-top: 0;
  }

  .author-date {
    @include font-size(12px);

    span, div {
      display: inline;
    }

    .line-sep {
      @include font-size(14px);
      margin: 0 2px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  .rrssb-buttons,
  .language-switcher-locale-url {
    list-style: none;
    padding-left: 0;
  }

  .read-news-link {
    font-size: 20px;
    font-weight: 700;
  }
}
