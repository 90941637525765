#block-views-exp-jobs-page {
  margin: auto;
  height: 43px;
  width: 440px;
}

#block-views-exp-jobs-page .content {
  height: 43px;
  width: 440px;
  position: relative;
}

#block-views-exp-jobs-page input[type="text"] {
  border: none;
  color: #888888;
  font-size: 12px;
  font-size: 0.75rem;
  height: 43px;
  width: 440px;
  padding: 0 40px 0 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

#block-views-exp-jobs-page input[type="submit"] {
  background: url("../images/icons/icon-magnifying-glass.png") no-repeat;
  border: none;
  height: 13px;
  width: 14px;
  position: absolute;
  top: 15px;
  right: 15px;
  overflow: hidden;
  text-indent: 9000px;
  color: #ffffff;
}

#block-views-exp-jobs-page .views-exposed-form .views-exposed-widget {
  float: none;
  padding: 0;
}

#block-views-exp-jobs-page .views-exposed-form .views-exposed-widget .form-submit {
  margin: 0;
}

#block-views-exp-jobs-page .ajax-progress {
  position: absolute;
  top: 13px;
  right: 30px;
}