.view-eds-directory .view-filters .views-submit-button input,
.view-eds-directory .view-filters .views-reset-button input {
  background: #fff;
  padding-right: 17px;
  padding: 8px 18px 6px 16px;
  border: 1px solid #c5c5c5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  outline: none;
  margin-right: 10px;
}

.view-eds-directory .view-filters .views-submit-button input:hover,
.view-eds-directory .view-filters .views-reset-button input:hover {
  color: $link;
}

.view-eds-directory #eds-directory-output,
.view-eds-directory .headers .category {
  display: none;
}

.view-eds-directory .headers {
  width: 100%;
}

.view-eds-directory .category {
  width: 0% !important;
  padding: 0;
}

.view-eds-directory .website {
  width: 60% !important;
  text-align: right;
}

.view-eds-directory .expand {
  cursor: pointer;
}

.view-eds-directory .active .field-name-field-eds-directory-website a {
  color: #ffffff;
}

.view-eds-directory .form-item .description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 8px;
  line-height: 1rem;
}

.page-eds-directory #block-system-help {
  display: none;
}

.page-eds-directory .main {
  padding-top: 0;
}