.main-button-video-clock {
  width: auto;
  margin-top: 30px;
  .left-blocks-in-main {
    width: 295px;
    float: left;
    .block-button {
      width: auto;
      height: 117px;
      text-align: center;
      position: relative;
      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        overflow: hidden;
        span {
          color: #fff;
          font-weight: 400;
          font-size: 22px;
          line-height: 18px;
        }
        .first-button {
          position: absolute;
          left: 65px;
          top: 43px;
        }
        .second-button {
          position: absolute;
          left: 72px;
          top: 31px;
          width: 143px;
          line-height: 28px;
        }
        .third-button {
          position: absolute;
          left: 72px;
          top: 31px;
          width: 143px;
          line-height: 28px;
        }
      }
    }
  }
  .right-blocks-in-main {
    width: 295px;
    float: right;
    .block-button {
      width: auto;
      height: 117px;
      text-align: center;
      position: relative;
      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        overflow: hidden;
        span {
          color: #fff;
          font-weight: 400;
          font-size: 22px;
          line-height: 18px;
        }
        .first-button {
          position: absolute;
          left: 65px;
          top: 43px;
          width: 143px;
        }
        .second-button {
          position: absolute;
          left: 72px;
          top: 31px;
          width: 143px;
          line-height: 28px;
        }
        .third-button {
          position: absolute;
          left: 72px;
          top: 31px;
          width: 143px;
          line-height: 28px;
        }
        .banner-read-more {
          background-image: url(/sites/all/themes/astrum/images/ico_read_more.png);
          width: 27px;
          height: 27px;
          display: block;
          float: right;
          margin-right: 25px;
          margin-top: 75px;
        }
      }
    }
  }
}
.blue-background {
  background-color: #0058a4;
}
.green-background {
  background-color: #6caf2d;
}
.yellow-background {
  background-color: #e9d417;
}