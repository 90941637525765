.node-type-member-profile h1.title {
  display: none;
}

.node-member-profile {
  position: relative;
}

.node-member-profile .field-name-field-mp-company-name {
  font-family: "myriad-pro", sans-serif;
  font-size: 38px;
  font-size: 2.375rem;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  padding-right: 440px;
  margin-bottom: 15px;
}

.node-member-profile .field-label {
  font-weight: 400;
}

.node-member-profile .gmap-gmap {
  position: absolute;
  top: 0;
  right: 0;
  height: 400px !important;
  width: 400px !important;
}

.node-member-profile .group-member-social-icons {
  margin-bottom: 120px;
  overflow: hidden;
}

.node-member-profile .field-name-field-mp-facebook .link-title,
.node-member-profile .field-name-field-mp-twitter .link-title,
.node-member-profile .field-name-field-mp-linkedin .link-title,
.node-member-profile .field-name-field-mp-youtube .link-title {
  display: none;
}

.node-member-profile .field-name-field-mp-facebook a,
.node-member-profile .field-name-field-mp-twitter a,
.node-member-profile .field-name-field-mp-linkedin a,
.node-member-profile .field-name-field-mp-youtube a {
  border: 1px solid #b7b7b7;
  color: #b7b7b7;
  display: block;
  float: left;
  height: 27px;
  width: 27px;
  margin-right: 5px;
  margin-top: 15px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
  text-align: center;
  overflow: hidden;
  position: relative;
  line-height: 28px;
}

.node-member-profile .field-name-field-mp-facebook a:hover,
.node-member-profile .field-name-field-mp-twitter a:hover,
.node-member-profile .field-name-field-mp-linkedin a:hover,
.node-member-profile .field-name-field-mp-youtube a:hover {
  border-color: $link;
  color: $link;
}

.node-member-profile .field-name-field-mp-details,
.node-member-profile .field-name-field-mp-biography {
  clear: both;
}

.node-member-profile .field-name-field-mp-biography {
  padding-top: 120px;
}

.node-member-profile .field-name-field-sites-and-buildings .field-label {
  font-family: "myriad-pro", sans-serif;
  font-size: 1.667rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-right: 440px;
  margin-bottom: 15px;
}