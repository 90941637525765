.front-features-one {
  padding-top: 100px;
  padding-bottom: 85px;
  background: #ffffff;
  .region {
    padding-left: 10px;
    padding-right: 10px;
    #block-block-6 {
      .block-title {
        color: #000;
        font: 700 36px 'Myriad Pro', sans-serif;
        line-height: 40px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .content {
        p {
          color: #a5a5a5;
          font: 400 20px 'Myriad Pro', sans-serif;
          line-height: 32px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

body.front {
  .front-features-one {
    #block-views-numbers-block {
      margin: 60px 0;
      .view-content {
        @include display-flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .views-row {
          text-align: center;
          -webkit-box-flex: 1;
          -ms-flex: 1 0 25%;
          flex: 1 0 25%;
          //min-width: 270px;
          &.views-row-last {
            border-right: none;
          }
          &:not(:last-child) {
            & > * {
              border-right: 1px solid #bfbfbf;
            }
          }
          .views-field-field-icon-number {
            .field-content {
              img {
                display: block;
                margin: 0 auto;
              }
            }
          }
          .views-field-field-statistics {
            color: #a5a5a5;
            font: 600 36px 'Myriad Pro', sans-serif;
            line-height: 36px;
          }
          .views-field-field-suffix {
            color: #282828;
            font: 600 18px 'Myriad Pro', sans-serif;
            line-height: 32px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
