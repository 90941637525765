.page-jobs #block-system-help, .page-jobs .street-address, .page-jobs .postal-code, .page-jobs .fn {
  display: none;
}

.page-jobs .banner-internal {
  height: 300px;
}

.page-jobs .main {
  padding-top: 0;
}

.page-jobs .views-row {
  border-bottom: 1px solid #888888;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.page-jobs .views-row-last {
  border-bottom: none;
}

.page-jobs .views-field-field-jobs-company-logo {
  background: #d4d4d4;
  float: left;
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin: 6px 20px 20px 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align-items: center;
  -moz-box-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.page-jobs .views-field-title {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.page-jobs .views-field-title a {
  color: #555555;
}

.page-jobs .views-field-title a:hover {
  color: $link;
}

.page-jobs .views-field-nothing {
  overflow: hidden;
  line-height: 10px;
  line-height: 1.25rem;
}

.page-jobs .views-field-nothing .jobs-company-name {
  float: left;
}

.page-jobs .views-field-nothing .jobs-location {
  float: left;
  border-left: 1px solid #888888;
  padding-left: 15px;
  margin-left: 15px;
}

.page-jobs .locality {
  margin-right: -5px;
  display: inline-block;
}

.node-jobs.node-teaser {
  border-bottom: 1px solid #888888;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.node-jobs.node-teaser:last-child {
  border-bottom: none;
}

.node-jobs.node-teaser h2 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.node-jobs.node-teaser h2 a {
  color: #555555;
}

.node-jobs.node-teaser h2 a:hover {
  color: $link;
}

.node-jobs.node-teaser .content {
  overflow: hidden;
  line-height: 10px;
  line-height: 1.25rem;
}

.node-jobs.node-teaser .field-name-field-jobs-company-name {
  float: left;
}

.node-jobs.node-teaser .field-name-field-jobs-location {
  float: left;
  border-left: 1px solid #888888;
  padding-left: 15px;
  margin-left: 15px;
}

.node-jobs.node-teaser .locality {
  margin-right: -5px;
  display: inline-block;
}

.node-jobs.node-teaser .links, .node-jobs.node-teaser .street-address, .node-jobs.node-teaser .postal-code, .node-jobs.node-teaser .fn {
  display: none;
}

.page-taxonomy #block-system-help {
  display: none;
}