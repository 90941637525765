@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/****** Usage for calculateRem function ******
   p {
     @include font-size(14px)
   }
*/

@mixin line-height($heightValue: 12 ) {
  line-height: $heightValue + px; //fallback for old browsers
  line-height: (0.125 * $heightValue) + rem;
}

/****** Usage for line-height mixin ******

   body {
     @include line-height (16);
   }
*/

@mixin font-serif {
  font-family: "Georgia", serif;
}

@mixin font-sans {
  font-family: "Helvetica", Arial, sans-serif;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin display-flex {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin smooth-transition($time, $prop: all) {
  -webkit-transition: $prop $time;
  -moz-transition: $prop $time;
  -ms-transition: $prop $time;
  -o-transition: $prop $time;
  transition: $prop $time;
}

h1, h2, h3, h4, h5, h6 {
  @include font-sans;
  font-weight: 600;
  margin: 0 0 0.555rem 0;
  color: $black;
}

body #block-block-6 h2 {
  font-size: 35px;
  font-weight: 700;
  color: #a5a5a5;
}

.container:after, .header:after, .body-container:after, .footer:after, .copyright:after, .node-type-eds-properties .main-content:after, .front-features-one .region:after, .front-features-two .region:after, .front-features-three .region:after, .front-features-four .region:after, .banner-internal #page-title:after, .navigation-top .region:after, .banner .views-field-nothing:after, body.page-eds-report .report-header-region:after, body.page-eds-report .comparison-remove:after, body.page-eds-report .main .section:after {
  content: "";
  display: table;
  clear: both;
}