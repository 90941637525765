.sidebar {

  .view-blog {

    .views-row {
      overflow: hidden;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 0;
      border-top: 1px solid #d4d4d4;
      border-bottom: 0;
    }

    .views-field-field-blog-image {
      float: left;
      margin-bottom: 0;
      padding-right: 10px;
      width: 31%;
    }

    .views-field-title {
      @include font-size(14px);
      @include line-height(9);

      a {
        color: $grey-dark;

        &:hover {
          color: $link;
        }
      }
    }

    .views-field-created {
      @include font-size(12px);
      @include line-height(8);
      text-align: left;
      color: #888;
    }
  }
}
