#block-block-6 .block-title {
  text-align: center;
  text-transform: uppercase;
}

#block-block-6 .content {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #a5a5a5;
}