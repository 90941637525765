.footer-container {
  background: #fff;
  // Logo block
  #block-block-14 {
    margin-bottom: 40px;
    img {
      display: block;
      margin: 0 auto;
    }
  }

  // Contact info block
  #block-block-3 {
    margin-bottom: 25px;
    .contact-info {
      @include display-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        border-right: 1px solid #000;
        color: #000;
        font: 400 14px "Myriad Pro", sans-serif;
        line-height: 18px;
        margin-right: 15px;
        padding-right: 15px;
        &:last-child {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}

#footer-bottom {
  .region-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media  (max-width: 479px) {
      text-align: center;

      #block-block-14,
      #block-views-social-media-icons-block-1 {
        margin-left: auto;
        margin-right: auto;
      }

      #block-views-social-media-icons-block-1,
      #block-block-35 {
        margin-top: 20px;
      }
    }

    .footer-info {
      p {
        line-height: 12px;

        @media  (max-width: 479px) {
          line-height: 20px;
        }
      }

      a {
        font-weight: 400;
        color: unset;
      }
    }
  }
}