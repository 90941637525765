.front-features-three {
  background: #f3f3f4;
  padding: 45px 0 10px;
  position: relative;
  &:before {
    background: #fff;
    content: '';
    position: absolute;
    top: 0;
    height: 25%;
    width: 100%;
  }
  .region {
    padding-left: 20px;
    padding-right: 20px;
  }
}

body {
  &.front {
    .front-features-three {
      #block-block-16 {
        padding: 0;
        width: 100%;
        .content {
          position: relative;
          p {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .video-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url("../images/video-thumb-default-new-4.jpg") no-repeat center center;
          background-size: cover;
          .video-play-button {
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 58px;
            height: 65px;
            background: url("../images/video-arrow-wt.png") no-repeat;
          }
        }
      }
    }
  }
}