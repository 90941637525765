div.sidebar .view-news .views-row {
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
  border-top: 1px solid #d4d4d4;
  border-bottom: 0;
}

div.sidebar .view-news .views-field-field-news-image {
  float: left;
  margin-bottom: 0;
  padding-right: 10px;
  width: 31%;
}

div.sidebar .view-news .views-field-title {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 9px;
  line-height: 1.125rem;
}

div.sidebar .view-news .views-field-title a {
  color: #333333;
}

div.sidebar .view-news .views-field-title a:hover {
  color: $link;
}

div.sidebar .view-news .views-field-created {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 8px;
  line-height: 1rem;
  color: #888888;
}