.node-type-jobs .node .content {
  overflow: hidden;
}

.node-type-jobs .banner-internal {
  height: 300px !important;
}

.node-type-jobs #page-title, .node-type-jobs .street-address, .node-type-jobs .postal-code, .node-type-jobs .fn {
  display: none;
}

.node-type-jobs .field-label {
  font-size: 18px;
  font-size: 1.125rem;
}

.node-type-jobs .field {
  margin-bottom: 25px;
}

.node-type-jobs .field-name-post-date {
  text-align: center;
}

.node-type-jobs .field-name-post-date .field-label,
.node-type-jobs .field-name-post-date .field-items {
  display: inline-block;
  float: none;
  overflow: hidden;
  margin: 0 auto;
}

.node-type-jobs .field-name-field-jobs-apply-link a {
  color: #ffffff;
  background: #888888;
  display: block;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
}

.node-type-jobs .field-name-field-jobs-apply-link a:hover {
  background: #555555;
}

.node-type-jobs .locality {
  margin-right: -5px;
  display: inline-block;
}

.group-jobs-company-info {
  float: left;
  text-align: center;
  width: 24%;
}

.group-jobs-company-info .field-name-jobs-back-to-jobs-button {
  text-align: left;
  height: 108px;
  border-bottom: 1px solid #888888;
  margin-bottom: 37px;
}

.group-jobs-company-info .field-name-jobs-back-to-jobs-button a {
  color: #333333;
  display: block;
  overflow: hidden;
}

.group-jobs-company-info .field-name-jobs-back-to-jobs-button a:hover {
  color: $link;
}

.group-jobs-company-info .field-name-jobs-back-to-jobs-button i {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 24px;
  padding-right: 10px;
  float: left;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .group-jobs-company-info .field-name-jobs-back-to-jobs-button i {
    margin-top: 2px;
  }
}

.group-jobs-company-info .field-name-jobs-back-to-jobs-button .button-text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 30px;
  float: left;
}

.group-jobs-company-info .field-name-field-jobs-company-logo {
  margin-bottom: 15px;
}

.group-jobs-company-info .field-name-field-jobs-company-logo img {
  margin: auto;
  max-width: 160px;
  height: auto;
}

.group-jobs-company-info .field-name-field-jobs-company-name {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.group-jobs-company-info .field-name-field-jobs-company-description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 11px;
  line-height: 1.375rem;
  margin-bottom: 20px;
}

.group-jobs-company-info .group-jobs-social-media-icons {
  overflow: hidden;
}

.group-jobs-social-media-icons {
  padding-bottom: 35px;
  display: inline-block;
}

.group-jobs-social-media-icons .field {
  float: left;
  height: 100%;
  line-height: 13px;
  list-style: none;
  margin: 0;
  padding: 0 2px;
}

.group-jobs-social-media-icons a {
  display: block;
  height: 36px;
  width: 36px;
  position: relative;
  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.group-jobs-social-media-icons .rrssb-facebook a {
  background-color: #306199;
}

.group-jobs-social-media-icons .rrssb-facebook a:hover {
  background-color: #244872;
}

.group-jobs-social-media-icons .rrssb-twitter a {
  background-color: #26c4f1;
}

.group-jobs-social-media-icons .rrssb-twitter a:hover {
  background-color: #0eaad6;
}

.group-jobs-social-media-icons .rrssb-linkedin a {
  background-color: #007bb6;
}

.group-jobs-social-media-icons .rrssb-linkedin a:hover {
  background-color: #005983;
}

.group-jobs-social-media-icons .rrssb-icon {
  left: auto;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: auto;
  width: 100%;
  display: block;
  padding-top: 9px;
  padding-bottom: 9px;
}

.group-jobs-social-media-icons .rrssb-icon svg {
  height: 17px;
  width: 17px;
}

.group-jobs-social-media-icons .rrssb-icon svg path {
  fill: #fff;
}

.group-jobs-job-content {
  float: right;
  width: 70%;
}

.field-name-field-jobs-job-title {
  font-size: 32px;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.group-jobs-status-name-cat {
  overflow: hidden;
  line-height: 10px;
  line-height: 1.25rem;
  border-bottom: 1px solid #888888;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.group-jobs-status-name-cat .field {
  border-left: 1px solid #888888;
  float: left;
  padding-left: 15px;
  margin: 0 15px 0 0;
}

.group-jobs-status-name-cat .field-name-field-jobs-job-type,
.group-jobs-status-name-cat .field-name-field-jobs-company-name {
  border-left: none;
  padding-left: 0;
  margin-left: 0;
}

.group-jobs-status-name-cat .field-name-field-jobs-company-name {
  margin: 0;
}

.page-node-1.one-sidebar div.main {
  float: none;
  width: 100%;
  padding-top: 0;
}