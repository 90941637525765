// General theme for header region.
.header-container {
  background: #ffffff;
}

body.not-front .banner-internal {
  width: 100%;
  display: table;
  background: #000;
}

body.not-front .banner-internal .region-banner-internal {
  background: rgba(0, 0, 0, 0.4);
}

body.not-front .banner-internal .region-banner-internal {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}

body.page-user.logged-in .body-container,
body.page-toboggan .body-container {
  margin-top: 125px;
}

body.page-user.not-logged-in .body-container {
  margin-top: 184px;
}

//Custom header styles due to clients request

#header {
  .container {
    .three  {
      width: 600px;

      @media only screen and (max-width: 1200px)  {
        width: 400px;
      }

      @media only screen and (max-width: 960px)  {
        width: 300px;
      }

      @media only screen and (max-width: 767px)  {
        width: 400px;
      }

      @media only screen and (max-width: 479px)  {
        width: 320px;
      }

      @media only screen and (max-width: 320px)  {
        width: 295px;
      }

      #logo {
        @media only screen and (max-width: 767px)  {
          width: 200px;
        }

        @media only screen and (max-width: 479px)  {
          width: 128px;
        }

        @media only screen and (max-width: 320px)  {
          width: 100px;
        }
      }
    }

    .thirteen {
      width: 500px;

      @media only screen and (max-width: 960px)  {
        width: 420px;
      }
    }
  }
}