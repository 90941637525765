.sidebar .block-taxonomy-menu-block {
  background: $grey-light;
  margin-bottom: 0;
  padding-right: 20px;
  padding-left: 20px;
  &:first-of-type {
    padding-top: 10px;
  }
  &#block-taxonomy-menu-block-5 {
    padding-bottom: 10px;
    .block-title:not(.expanded) {
      border-bottom: none;
    }
  }
}

.sidebar .block-taxonomy-menu-block .block-title {
  border-bottom: 1px solid #d4d4d4;
  margin: 0;
  color: #333333;
  font-weight: 400;
  padding: 3px 20px 3px 0;
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  text-transform: none;
  -webkit-transition: background-color ease-in-out .2s;
  -moz-transition: background-color ease-in-out .2s;
  -o-transition: background-color ease-in-out .2s;
  transition: background-color ease-in-out .2s;
}

.sidebar .block-taxonomy-menu-block .block-title:hover,
.sidebar .block-taxonomy-menu-block .block-title.expanded {
  background-color: #eeeeee;
  color: $link;
  cursor: pointer;
}

.sidebar .block-taxonomy-menu-block .block-title.expanded {
  border-right: 3px solid #1a77bd;
  border-bottom: 1px solid #d4d4d4;
}

.sidebar .block-taxonomy-menu-block .block-title i {
  font-size: 20px;
  font-size: 1.25rem;
  color: #333333;
  position: absolute;
  top: 50%;
  right: 4%;
  z-index: 300;
  margin-top: -12px;
}

.sidebar .block-taxonomy-menu-block .content {
  display: none;
}

.sidebar .block-taxonomy-menu-block .content ul {
  padding: 0;
  margin: 0;
}

.sidebar .block-taxonomy-menu-block .content ul li {
  padding: 0 0 0 10px;
  margin: 0;
  list-style: none;
  list-style-image: none;
}

.sidebar .block-taxonomy-menu-block .content ul li a {
  display: block;
  color: #333333;
  padding: 3px 30px 3px 10px;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: 1px solid #d4d4d4;
  -webkit-transition: background-color ease-in-out .2s;
  -moz-transition: background-color ease-in-out .2s;
  -o-transition: background-color ease-in-out .2s;
  transition: background-color ease-in-out .2s;
}

.sidebar .block-taxonomy-menu-block .content ul li a:hover {
  background-color: #eeeeee;
}

.sidebar .block-taxonomy-menu-block .content ul li a.active {
  color: $link;
  background-color: #eeeeee;
  border-right: 3px solid $link;
}

.sidebar .block-taxonomy-menu-block .content ul li.last a {
  border-bottom: none;
}

.sidebar #block-taxonomy-menu-block-5 {
  //border-bottom: 1px solid #d4d4d4;
  margin-bottom: 40px;
}