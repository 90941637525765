.banner {
  overflow: hidden;
}

.banner .flexslider {
  margin: 0;
  background: none;
  border: none;
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  zoom: 1;
}

.banner .views-field-field-banner-image {
  position: absolute;
  width: 1440px;
  height: 640px;
  z-index: 0;
  top: 0;
  left: 50%;
  margin-left: -700px;
  display: block;
}

.banner .views-field-field-banner-image img {
  max-width: inherit;
  width: 1440px;
  height: 640px;
  display: block;
}

.banner .views-field-nothing {
  position: relative;
  z-index: 10;
  height: 640px;
}

.banner .views-field-nothing .banner-headline {
  font-size: 42px;
  font-size: 2.625rem;
  line-height: 22px;
  line-height: 2.75rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.banner .views-field-nothing .banner-description {
  font-family: "Georgia", serif;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 14px;
  line-height: 1.75rem;
  font-style: italic;
  margin-bottom: 25px;
}

.banner .views-field-nothing .banner-text {
  position: absolute;
  width: 700px;
  padding: 10px 20px 20px 20px;
}

.banner .views-field-nothing .banner-link a {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  color: #000000;
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 8px;
  line-height: 1rem;
  font-weight: 600;
  padding: 13px 30px 12px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.banner .views-field-nothing .banner-link a:hover {
  background: $link;
  border: 1px solid $link;
  color: #ffffff;
}

.banner .views-field-nothing .White {
  color: #ffffff;
}

.banner .views-field-nothing .Black {
  color: #000000;
}

.banner .views-field-nothing .White_Black {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4);
}

.banner .views-field-nothing .Top_Left {
  top: 45px;
  left: 0;
}

.banner .views-field-nothing .Top_Right {
  top: 45px;
  right: 0;
  text-align: right;
}

.banner .views-field-nothing .Bottom_Left {
  bottom: 45px;
  left: 0;
}

.banner .views-field-nothing .Bottom_Right {
  bottom: 45px;
  right: 0;
  text-align: right;
}

.banner .views-field-nothing .Center_Top,
.banner .views-field-nothing .Center_Middle,
.banner .views-field-nothing .Center_Bottom {
  left: 50%;
  margin-left: -350px;
  text-align: center;
}

.banner .views-field-nothing .Center_Top {
  top: 45px;
}

.banner .views-field-nothing .Center_Middle {
  top: 200px;
}

.banner .views-field-nothing .Center_Bottom {
  bottom: 55px;
}

.banner .views-field-nothing .White_Black.Bottom_Left {
  left: 20px;
}

.banner .views-field-nothing .White_Black.Bottom_Right {
  right: 20px;
}

.banner .views-field-nothing .White_Black.Top_Left {
  left: 20px;
}

.banner .views-field-nothing .White_Black.Top_Right {
  right: 20px;
}

.banner .flex-control-nav {
  bottom: 0px;
  z-index: 30;
}

.banner .flex-control-nav li a {
  background: none;
  border: 1px solid #ffffff;
  height: 12px;
  width: 12px;
  box-shadow: none;
}

.banner .flex-control-nav li a.flex-active {
  background: #ffffff;
}

.banner div.flexslider:hover .flex-direction-nav li,
.banner div.flexslider .flex-direction-nav li {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  opacity: 0.5;
  z-index: 200;
}

.banner div.flexslider:hover .flex-direction-nav li:hover,
.banner div.flexslider .flex-direction-nav li:hover {
  opacity: 1;
}

.banner div.flexslider:hover .flex-direction-nav li i,
.banner div.flexslider .flex-direction-nav li i {
  top: 2px;
}

.banner div.flexslider:hover .flex-direction-nav li:first-child,
.banner div.flexslider .flex-direction-nav li:first-child {
  left: 0;
  top: 46%;
}

.banner div.flexslider:hover .flex-direction-nav li:first-child i,
.banner div.flexslider .flex-direction-nav li:first-child i {
  left: 17px;
}

.banner div.flexslider:hover .flex-direction-nav li:last-child,
.banner div.flexslider .flex-direction-nav li:last-child {
  right: 0;
  top: 46%;
}

.banner div.flexslider:hover .flex-direction-nav li:last-child i,
.banner div.flexslider .flex-direction-nav li:last-child i {
  right: 17px;
}

.banner div.flexslider:hover .flex-direction-nav i,
.banner div.flexslider .flex-direction-nav i {
  font-size: 56px;
  font-size: 3.5rem;
  font-weight: 400;
  color: #ffffff;
  z-index: 200;
  position: absolute;
}

.banner div.flexslider:hover .flex-direction-nav a,
.banner div.flexslider .flex-direction-nav a {
  background: transparent;
  background-image: none;
  height: 60px;
  width: 60px;
  top: inherit;
  left: inherit;
  right: inherit;
  position: relative;
  margin: 0;
  opacity: 1;
  z-index: 250;
}

#block-views-banner-block #flexslider-1 {
  width: 690px;
  height: 525px;
  overflow: hidden;
  float: left;
  border-radius: 0;
  .slides {
    position: relative;
    width: 100%;
    .block-banner-image {
      position: relative;
      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        text-indent: -9999px;
        overflow: hidden;
      }
    }
    .views-field-nothing {
      position: absolute;
      display: table;
      width: 250px;
      height: 525px;
      top: 0;
      right: 0;
      color: #fff ! important;
      padding: 60px 25px 0;
      background-color: rgba(0, 0, 0, 0.8);
      .banner-headline h2 {
        font-size: 29px;
        color: #fff;
        font-weight: normal;
      }
      .banner-description {
        margin-top: 15px;
        margin-bottom: 15px;
        float: left;
        font-size: 1em;
        overflow: hidden;
        line-height: 25px;
        font-weight: 100;
        color: #a1a2a5;
      }
      .banner-link {
        a {
          .banner-read-more {
            background-image: url(/sites/all/themes/astrum/images/ico_read_more.png);
            width: 27px;
            height: 27px;
            display: block;
            float: right;
          }
          .banner-read-more:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .flex-control-nav {
    width: auto;
    position: absolute;
    bottom: 24px;
    text-align: center;
    z-index: 99;
    right: 42px;
    li {
      width: 22px;
      height: 22px;
      //background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      border: 2px solid #cccccc;
      a {
        cursor: pointer;
        display: block;
        float: left;
        width: 14px;
        height: 14px;
        margin-left: 2px;
        margin-top: 2px;
        border-radius: 50%;
        text-indent: -9999px;
      }
      a.flex-active,
      a:hover{
        background-color: #cccccc;
      }
    }
  }
}