.node-type-eds-properties {
  .sidebar {
    display: none;
  }

  .main {
    width: 100%;
    float: none;
    padding-top: 0;
  }

  .body-container {
    max-width: inherit;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  #quicks {
    position: absolute;
    right: 0;
    top: -50px;

    img {
      display: inline-block;
    }
  }

  .node-eds-properties {
    position: relative;
  }

  div.report_change {
    float: none;
    position: absolute;
    top: -70px;
    right: 0;

    span {
      top: 0;
      margin-bottom: 0;
    }
  }

  #image-Container {
    img {
      width: inherit;
      height: auto;
      padding: 0;
    }
  }

  div#photos div#buttons {
    margin-top: 3px;
  }

  div#photos div#buttons img {
    display: inline-block;
    margin-top: 5px;
  }
}

.node-type-eds-properties #location {
  float: left;
  height: 500px;
  width: 50%;
}

.node-type-eds-properties .gmap-gmap {
  height: 500px !important;
}

.node-type-eds-properties #photos {
  float: right;
  height: 500px;
  width: 50%;
  position: relative;
}

.node-type-eds-properties #image-Container {
  overflow: hidden;
  height: 500px;
  position: relative;
  width: 100%;
}

.node-type-eds-properties #image-Container img {
  max-width: inherit;
  width: 800px;
  margin: 0;
}

.node-type-eds-properties #buttons,
.node-type-eds-properties #count-photo,
.node-type-eds-properties #buttons-enlarge,
.node-type-eds-properties #slideshow-status {
  font-size: 14px;
  font-size: 0.875rem;
  color: #ffffff;
  position: absolute;
  z-index: 300;
}

.node-type-eds-properties #buttons {
  bottom: 20px;
  left: 20px;
}

.node-type-eds-properties #buttons i {
  margin-right: 10px;
  cursor: pointer;
}

.node-type-eds-properties #count-photo {
  bottom: 20px;
  left: 135px;
}

.node-type-eds-properties #buttons-enlarge {
  bottom: 20px;
  left: 220px;
}

.node-type-eds-properties #buttons-enlarge a {
  color: #ffffff;
}

.node-type-eds-properties div#photos div#buttons {
  margin-top: 3px;
}

.node-type-eds-properties div#photos div#buttons img {
  display: inline-block;
  margin-top: 5px;
}

.node-type-eds-properties .caption.none p {
  display: none;
}

.node-type-eds-properties .mfp-gallery-image {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.node-type-eds-properties .mfp-gallery-image .mfp-thumbnail {
  margin-right: 20px;
  width: auto !important; //important to replace inline width which set by module
}

.node-type-eds-properties .mfp-container .mfp-arrow {
  background-image: none;
}

.node-type-eds-properties .main-content {
  clear: both;
  padding-top: 20px;
}

.node-type-eds-properties .section-title {
  background: #d4d4d4;
  color: #000000;
  font-family: "myriad-pro", sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 9px;
  line-height: 1.125rem;
  padding: 7px 10px;
  margin-bottom: 10px;
}

.node-type-eds-properties .section-label {
  color: #000000;
  font-weight: 600;
}

.node-type-eds-properties .label {
  color: #000000;
  font-weight: 600;
}

.node-type-eds-properties #address-full {
  float: left;
  width: 50%;
  overflow: hidden;
  padding: 0 10px 0 0;
  margin-bottom: 20px;
  min-height: 300px;
}

.node-type-eds-properties #address-short {
  background: #555555;
  color: #ffffff;
  font-family: "myriad-pro", sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 600;
  padding: 20px;
  margin-bottom: 20px;
}

.node-type-eds-properties .adr {
  clear: both;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.node-type-eds-properties #primary-contact,
.node-type-eds-properties #alternate-contact {
  float: left;
  width: 50%;
  padding: 0 10px;
}

.node-type-eds-properties .sale-lease {
  float: left;
  width: 50%;
  overflow: hidden;
  padding: 0 0 0 10px;
  margin-bottom: 20px;
  min-height: 300px;
}

.node-type-eds-properties #sale_actual_price {
  background: #555555;
  color: #ffffff;
  font-family: "myriad-pro", sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 600;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
}

.node-type-eds-properties #sale_actual_price .label {
  color: #ffffff;
}

.node-type-eds-properties #property-details {
  clear: both;
  margin-bottom: 20px;
  overflow: hidden;
}

.node-type-eds-properties #property-details .content {
  padding-left: 10px;
}

.node-type-eds-properties #property-details .content div {
  float: left;
  width: 50%;
  padding-right: 20px;
}

.node-type-eds-properties #property-details .content div#incentive_zones,
.node-type-eds-properties #property-details .content div#environmental_statement {
  float: none;
  width: 100%;
  clear: both;
}

.node-type-eds-properties #additional-information {
  clear: both;
}

.node-type-eds-properties #additional-information .content {
  padding: 0 10px;
}

.node-type-eds-properties #quicks {
  top: 20px;
  right: 20px;
  z-index: 320;
}

.node-type-eds-properties #quicks a {
  background: #fff;
  font-size: 14px;
  padding-right: 17px;
  padding: 0 18px 0 16px;
  border: 1px solid #c5c5c5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  outline: none;
  display: block;
  width: 150px;
  height: 30px;
  line-height: 30px;
}

.node-type-eds-properties #quicks a i {
  margin-right: 5px;
  font-size: 17px;
}

.node-type-eds-properties div.report_change {
  float: none;
  position: absolute;
  top: 20px;
  right: 180px;
  z-index: 320;
}

.node-type-eds-properties div.report_change span {
  top: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .node-type-eds-properties #address-short,
  .node-type-eds-properties #sale_actual_price {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .node-type-eds-properties #location,
  .node-type-eds-properties #photos,
  .node-type-eds-properties #image-Container,
  .node-type-eds-properties #address-full,
  .node-type-eds-properties .sale-lease,
  .node-type-eds-properties #property-details .content div {
    float: none;
    width: 100%;
    clear: both;
  }
}

@media only screen and (max-width: 767px) {
  .node-type-eds-properties #location,
  .node-type-eds-properties .gmap-gmap,
  .node-type-eds-properties #photos,
  .node-type-eds-properties #image-Container {
    height: 256px !important;
  }
  .node-type-eds-properties #image-Container img {
    width: 414px;
  }
  .node-type-eds-properties #primary-contact,
  .node-type-eds-properties #alternate-contact {
    float: none;
    width: 100%;
  }

  #buttons-enlarge {
    display: none;
  }
}
