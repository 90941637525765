.navigation-top {

  .block-menu {
    float: left;
    overflow: hidden;
    margin-right: 25px;
    margin-top: 15px;
  }

  .menu {
    margin: 0;
    padding: 0;
    overflow: hidden;

    li {
      float: left;
      list-style: none;
      list-style-image: none;
      padding: 0;
      margin: 0;

      a {
        color: $grey-dark;
        @include font-size(12px);
        display: block;
        font-family: "myriad-pro", sans-serif;
        padding: 0;
        margin-right: 17px;
        line-height: 100%;

        &:hover {
          color: $link;
        }
      }
    }
  }
}

#navigation #block-superfish-1 {
  display: block;
  ul {
    margin-top: 0px;
    float: right;
    li {
      float: left;
      a {
        &:hover {
          border-color: #73B819;
        }
        .active {
          border-color: #73B819;
        }
      }
    }
  }
}

#navigation #block-locale-language {
  display: block;
  margin-bottom: 10px;
  margin-top: -4px;
  float: right;
   .language-switcher-locale-url {
     padding: 0;
     margin-top: 45px;
     li {
       float: left;
       margin-right: 5px;
       .language-icon {
         p {
           display: none;
         }
         .language-link {
           color: #888;
           &:hover {
             color: #73B819;
           }
           img{
             display: inline-block;
             width: 16px;
             height: 12px !important;
           }
         }
         .active {
           color: #73B819;
         }
       }
     }
   }
 }

//#navigation
#block-views-social-media-icons-block {
  float: right;
  margin-top: 35px;
  margin-right: 30px;
  margin-bottom: 8px;
  margin-left: 5px;
  display: block;
}
#navigation #block-search-form {
  float: right;
  margin-left: 25px;
  margin-top: 7px;
  display: block;
}
#navigation #block-block-21 {
  display: block;
  float: right;
}
#navigation .region-main-menu {
  display: inline-block;
  text-align: right;
}
.not-clickable {
  cursor: default;
}