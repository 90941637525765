#recent-works-wrapper {
  margin-top: 20px;
}

.showworks {
  position: relative;
  ul {
    width: auto;
    padding: 0;
    .views-row {
      display: inline-block;
      .portfolio-item {
        position: relative;
        .item-description {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 11px 20px 5px 20px;
          h5 {
            line-height: 20px;
            font-size: 16px;
            color: #fff;
            font-weight: normal;
          }
          span {
            line-height: 25px;
            font-weight: 100;
            color: #a1a2a5;
            display: none;
          }
        }
      }

    }
  }
}

.showworks .views-row-1,
.showworks .views-row-6 {
  width: 405px;
}

.showworks .views-row-2,
.showworks .views-row-3,
.showworks .views-row-4,
.showworks .views-row-5 {
  width: 197px;
}

.showworks .views-row-2,
.showworks .views-row-4 {
  margin-right: 8px;
}

.works-block-row {
  display: inline-block;
  width: 413px;
}

.showworks ul .works-block-row {
  display: none;
}

.showworks ul .works-block-row:nth-child(1),
.showworks ul .works-block-row:nth-child(2) {
  display: inline-block;
  width: 413px;
}

.showworks .views-row:hover .portfolio-item .item-description {
  background-color: rgba(0, 0, 0, 0.9);
}

#block-block-29,
#block-block-30 {
  width: 810px;
  .link-to-works {
    background: url(/sites/all/themes/astrum/images/more-works.png) no-repeat;
    background-position: right;
    height: 30px;
    float: right;
    margin-right: 15px;
    margin-top: -8px;
    margin-bottom: 10px;
    a {
      color: #336699;
      line-height: 30px;
      padding-right: 35px;
      font-size: 18px;
    }
  }
  .link-to-works:hover {
    opacity: 0.6;
  }
}

#block-views-recent-works-block-1,
#block-views-recent-works-block {
  width: 830px;
  display: block;
  float: left;
}

#block-block-31,
#block-block-32 {
  width: 338px;
  display: inline-block;
  margin-left: 12px;
  .link-to-exhibitions {
    background: url(/sites/all/themes/astrum/images/calendar_2019_eng.jpg) center no-repeat;
    background-size:cover;
    width: 338px;
    height: 441px;
    display: inline-block;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
    }

  }
}

#block-block-31 .link-to-exhibitions {
  background: url(/sites/all/themes/astrum/images/calendar_2019.jpg) center no-repeat;
  background-size:cover;
}

#block-bean-block-video-on-main,
#block-bean-block-video-on-main-en {
  width: 1180px;
  height: 351px;
  .field-name-field-text-for-video {
    display: block;
    position: absolute;
    top: 100px;
    h5 {
      font-family: "miriad-bold";
      font-size: 47px;
      color: #fffefe;
      margin: 0 0 25px 50px;
    }
    span {
      font-family: "miriad-regular";
      font-size: 41px;
      color: #fffefe;
      margin: 0 0 0 50px;
    }

  }
  .field-name-field-video {
    width: 36px;
    height: 36px;
    position: absolute;
    display: block;
    top: 240px;
    left: 50px;

    .magnific_popup-processed,
    span {
        width: 285px;
        height: 36px;
        display: block;
        position: relative;
        a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0;
          &:before {
            content: '';
            background: url(/sites/all/themes/astrum/images/link-video-bg.jpg) no-repeat;
            width: 36px;
            height: 36px;
            float: left;
          }
          img {
            display: none;
          }

          p {
            margin: 0;
            font-family: "Open Sans";
          }
        }
        p {
          font-size: 22px;
          color: #fffefe;
          text-transform: uppercase;
          letter-spacing: 1px;
          width: 280px;
          display: block;
          padding-top: 7px;
          padding-left: 45px;
          font-family: "Open Sans";
        }
    }
  }
}