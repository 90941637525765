#block-search-form {
  //position: absolute;
  //top: 25px;
  //right: 20px;
  //height: 33px;
  //width: 220px;

  display: inline-block;

  .content {
    height: 33px;
    width: 220px;
    position: relative;
  }

  input[type="text"] {
    @include font-size(12px);
    background: #efefef;
    border: 1px solid $grey-light;
    color: #a2a2a2;
    font-weight: 600;
    height: 33px;
    width: 220px;
    padding: 0 40px 0 15px;
    &:focus {
      color: #333333;
      font-weight: 400;
    }
  }

  input[type="submit"] {
    background: url('../images/icons/icon-magnifying-glass.png') no-repeat;
    border: none;
    height: 13px;
    width: 14px;
    position: absolute;
    top: 12px;
    right: 11px;
    display: none; /* hide for this site */
  }
}
