#block-views-board-members-block .block-title {
  font-size: 16px;
  font-size: 1rem;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 5px;
  margin-bottom: 20px;
  width: 100%;
}

#block-views-board-members-block .views-row {
  float: left;
  padding-right: 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 33.333%;
}

#block-views-board-members-block .views-field-field-board-member-picture {
  margin-bottom: 10px;
}

#block-views-board-members-block .views-field-field-board-member-name {
  font-size: 20px;
  font-size: 1.25rem;
  color: #000000;
  font-weight: bold;
}

#block-views-board-members-block .views-field-field-board-member-title {
  font-size: 14px;
  font-size: 0.875rem;
  color: #000000;
}

#block-views-board-members-block .views-field-field-board-member-email a {
  font-size: 14px;
  font-size: 0.875rem;
  color: #333333;
  border: 1px solid #333333;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  text-align: center;
  display: inline-block;
}

#block-views-board-members-block .views-field-field-board-member-email a:hover {
  background: $link;
  border: 1px solid $link;
  color: $white;
}

#block-views-board-members-block .views-field-field-board-member-email a:hover i {
  color: #ffffff;
}

#block-views-board-members-block .views-field-field-board-member-email a i {
  font-size: 11px;
  font-size: 0.6875rem;
  display: block;
  margin-top: 6px;
}