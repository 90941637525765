#block-views-staff-members-block {
  margin-bottom: 20px;
}

#block-views-staff-members-block .block-title {
  font-size: 16px;
  font-size: 1rem;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 5px;
  margin-bottom: 20px;
  width: 100%;
}

#block-views-staff-members-block .views-row {
  overflow: hidden;
  clear: both;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;
}

#block-views-staff-members-block .views-row.views-row-last {
  border-bottom: none;
}

#block-views-staff-members-block .views-field-field-staff-picture {
  float: left;
  padding-right: 20px;
  width: 30%;
}

#block-views-staff-members-block .views-field-nothing {
  float: left;
  width: 70%;
}

#block-views-staff-members-block .no-image .views-field-nothing {
  float: none;
  width: 100%;
}

#block-views-staff-members-block .staff-name {
  font-size: 24px;
  font-size: 1.5rem;
  color: #000000;
  font-weight: bold;
}

#block-views-staff-members-block .staff-title {
  font-size: 16px;
  font-size: 1rem;
  color: #000000;
  margin-bottom: 5px;
}

#block-views-staff-members-block .staff-bio {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 11px;
  line-height: 1.375rem;
  margin-bottom: 15px;
}

#block-views-staff-members-block .staff-facebook,
#block-views-staff-members-block .staff-instagram,
#block-views-staff-members-block .staff-linkedin,
#block-views-staff-members-block .staff-twitter,
#block-views-staff-members-block .staff-email {
  float: left;
  margin-right: 7px;
}

#block-views-staff-members-block .staff-facebook a,
#block-views-staff-members-block .staff-instagram a,
#block-views-staff-members-block .staff-linkedin a,
#block-views-staff-members-block .staff-twitter a,
#block-views-staff-members-block .staff-email a {
  font-size: 14px;
  font-size: 0.875rem;
  color: #333333;
  border: 1px solid #333333;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  text-align: center;
  display: block;
}

#block-views-staff-members-block .staff-facebook a:hover,
#block-views-staff-members-block .staff-instagram a:hover,
#block-views-staff-members-block .staff-linkedin a:hover,
#block-views-staff-members-block .staff-twitter a:hover,
#block-views-staff-members-block .staff-email a:hover {
  background: $link;
  border: 1px solid $link;
  color: $white;
}

#block-views-staff-members-block .staff-facebook a:hover i,
#block-views-staff-members-block .staff-instagram a:hover i,
#block-views-staff-members-block .staff-linkedin a:hover i,
#block-views-staff-members-block .staff-twitter a:hover i,
#block-views-staff-members-block .staff-email a:hover i {
  color: #ffffff;
}

#block-views-staff-members-block .staff-facebook a i,
#block-views-staff-members-block .staff-instagram a i,
#block-views-staff-members-block .staff-linkedin a i,
#block-views-staff-members-block .staff-twitter a i,
#block-views-staff-members-block .staff-email a i {
  font-size: 11px;
  font-size: 0.6875rem;
  display: block;
  margin-top: 6px;
}