.page-agendas #block-system-help {
  display: none;
}

.page-agendas .main {
  padding-top: 0;
}

.view-agendas .view-header {
  font-size: 14px;
  font-size: 0.875rem;
  color: #000000;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
}

.view-agendas .views-row {
  padding-top: 20px;
  position: relative;
  margin-bottom: 19px;
  border-top: 1px solid #d4d4d4;
  overflow: hidden;
}

.view-agendas .views-field {
  float: right;
  margin-left: 10px;
  margin-top: 11px;
  width: 30px;
}

.view-agendas .views-field-title {
  line-height: 14px;
  line-height: 1.75rem;
  float: left;
  margin-left: 0;
  margin-top: 0;
  width: 100%;
  padding-right: 160px;
}

.view-agendas .views-field-title .field-content {
  overflow: hidden;
}

.view-agendas .meeting-title {
  display: inline;
}

.view-agendas .views-field-nothing {
  width: 150px;
  overflow: hidden;
  float: none;
  position: absolute;
  top: 25px;
  right: 0;
  margin: 0;
  padding: 0;
}

.view-agendas .views-field-field-meeting-canceled {
  font-family: verdana, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 8px;
  line-height: 1rem;
  background: #FF0800;
  color: #ffffff;
  font-weight: 600;
  padding: 4px;
  display: block;
  margin: 0 0 11px 0;
  float: left;
  clear: left;
  width: 180px;
  text-align: center;
  text-transform: uppercase;
}

.view-agendas .views-field-created {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 8px;
  line-height: 1rem;
  float: left;
  clear: left;
  margin-left: 0;
  margin-top: 0;
  width: 150px;
}

.view-agendas .views-field-field-agenda-description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 11px;
  line-height: 1.375rem;
  float: none;
  clear: both;
  margin-left: 0;
  padding-top: 6px;
  width: 100%;
}

.view-agendas i {
  font-size: 28px;
  font-size: 1.75rem;
  color: #333333;
  margin-left: 10px;
  float: right;
}

.view-agendas i:hover {
  color: $link;
}