.front-features-two {
  padding-top: 100px;
  padding-bottom: 80px;
}

.front-features-two .region {
  padding-left: 10px;
  padding-right: 10px;
}

.front-features-two .block,
.front-features-two .region {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align-items: stretch;
  -moz-box-align-items: stretch;
  -webkit-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-box-justify-content: center;
  -moz-box-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.front-features-two .block {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.front-features-two .block-content-container {
  background: #ffffff;
  border: 1px solid #d4d4d4;
  padding: 20px 20px 5px 20px;
}