.view-partners-and-international-purchases-view {

  .views-row {
    padding-bottom: 20px;
    margin-bottom: 50px;
    border-bottom: 1px solid #d4d4d4;

    &:last-child {
      border-bottom: none;
    }
  }

  .partners-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    @media only screen and (max-width: 767px)  {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .partners-img {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 30%;
      flex: 0 0 30%;
    }

    .partners-info {
      margin-left: 30px;

      @media only screen and (max-width: 767px)  {
        margin-left: 0;
        margin-top: 30px;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 5px;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
    }
  }
}