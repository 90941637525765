body.page-eds-report {
  background: #eeeeee;
  margin: 0;
  position: relative;
  .site-container {
    padding-top: 0 !important;
  }
}

body.page-eds-report .header-container,
body.page-eds-report .navigation-main-2,
body.page-eds-report .footer-container,
body.page-eds-report .contact,
body.page-eds-report .sidebar,
body.page-eds-report .copyright-container,
body.page-eds-report #quicks {
  display: none;
}

body.page-eds-report .main .page,
body.page-eds-report .main .news,
body.page-eds-report .main .book,
body.page-eds-report .main .blog,
body.page-eds-report .main .eds_properties {
  display: block;
}

body.page-eds-report div.sticky-header {
  position: relative;
  width: 100%;
  height: inherit;
  background: #ffffff;
  z-index: inherit;
  border-bottom: none;
  top: inherit;
}

body.page-eds-report div.padding_top {
  padding-top: 0;
}

body.page-eds-report .header-report-top {
  background: #262626;
}

body.page-eds-report .header-report-top-region {
  font-family: "myriad-pro", sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 1.5rem;
  height: 54px;
  position: relative;
}

body.page-eds-report .custom-report-title {
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  left: 25px;
}

body.page-eds-report .custom-report-title i {
  font-size: 14px;
  font-size: 0.875rem;
  margin-right: 10px;
}

body.page-eds-report .report-header-region {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align-items: center;
  -moz-box-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

body.page-eds-report #report-contact-information {
  text-align: right;
  line-height: 11px;
  line-height: 1.375rem;
}

body.page-eds-report .comparison-remove {
  position: relative;
  min-height: 31px;
  margin-bottom: 23px;
}

body.page-eds-report .comparison-remove a.csv {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 8px;
  line-height: 1rem;
  padding: 6px 18px 6px 16px;
  color: #ffffff;
  background: $link;
  border: none;
  position: absolute;
  top: 2px;
  right: 295px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

body.page-eds-report .comparison-remove a.csv:hover {
  background: #1d85d3;
}

body.page-eds-report .comparison-remove a.csv i {
  margin-right: 5px;
}

body.page-eds-report .body-container {
  max-width: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
}

body.page-eds-report .main {
  float: none;
  width: 100%;
  padding-right: 0px;
  padding-top: 0px;
}

body.page-eds-report h2.title {
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
}

body.page-eds-report .node {
  background: #ffffff;
  border: #ddd solid 1px;
  margin-bottom: 5px;
  padding: 20px;
}

body.page-eds-report #page-list {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

body.page-eds-report div#report-header {
  background: #ffffff;
  border-bottom: #ddd solid 1px;
  display: block;
  margin-bottom: 25px;
  padding: 0;
}

body.page-eds-report .report_change {
  position: relative;
  top: inherit;
  right: inherit;
  float: right;
}

body.page-eds-report .report_change span {
  margin-right: 0;
}

body.page-eds-report .report_change_all {
  position: absolute;
  z-index: 999;
  top: 0px;
  right: 20px;
}

body.page-eds-report .report_change_all a {
  color: #ffffff;
  background: #1a77bd;
  border: none;
}

body.page-eds-report .report_change_all a:hover {
  background: #1d85d3;
}

body.page-eds-report #block-system-help {
  position: absolute;
  top: 0;
  width: 100%;
  height: 55px;
  margin: 0;
}

body.page-eds-report #block-system-help .content {
  text-align: right;
  height: 55px;
  padding-top: 11px;
}

body.page-eds-report #block-system-help span {
  padding: 0;
  margin: 0;
}

body.page-eds-report #block-system-help .print_mail a,
body.page-eds-report #block-system-help .print_pdf a {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 8px;
  line-height: 1rem;
  padding: 6px 18px 6px 16px;
  color: #ffffff;
  background: #1a77bd;
  border: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-right: 25px;
}

body.page-eds-report #block-system-help .print_mail a img,
body.page-eds-report #block-system-help .print_pdf a img {
  display: none;
}

body.page-eds-report #block-system-help .print_mail a i,
body.page-eds-report #block-system-help .print_pdf a i {
  margin-right: 5px;
}

body.page-eds-report #block-system-help .print_mail a:hover,
body.page-eds-report #block-system-help .print_pdf a:hover {
  background: #1d85d3;
}

body.page-eds-report #block-system-help .print_mail a {
  margin-right: 20px;
}