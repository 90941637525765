#block-views-blog-block-3 {
  position: relative;
  padding-left: 20px;
}

#block-views-blog-block-3 .view-header {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px;
}

#block-views-blog-block-3 .views-row {
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  border-top: 1px solid #d4d4d4;
  border-bottom: 0;
}

#block-views-blog-block-3 .views-field-field-blog-image {
  float: left;
  margin-bottom: 20px;
  padding-right: 20px;
}

#block-views-blog-block-3 .views-field-title h2 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 11px;
  line-height: 1.375rem;
  margin: 0;
}

#block-views-blog-block-3 .views-field-title a {
  color: #333333;
}

#block-views-blog-block-3 .views-field-title a:hover {
  color: $link;
}

#block-views-blog-block-3 .views-field-field-blog-authors,
#block-views-blog-block-3 .views-field-created {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 8px;
  line-height: 1rem;
  color: #888888;
}

#block-views-blog-block-3 .views-field-body {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 13px;
  line-height: 1.625rem;
}