div#save-and-report-container {
   font-size: 1rem;
   padding: 0;
   position: relative;;
   right: 0 !important;
   top: 0 !important;
   left: inherit !important;
   z-index: 499;
   height: 54px;
   width: 100%;

   &.sticky-report {
      position: fixed;
   }

   #save-and-report {
      @include font-size(12px);
      @include line-height(12);
      background: darken($grey-dark, 5%);
      font-family: "myriad-pro", sans-serif;
      position: relative;
      width: 100%;
      height: 54px;

      .custom-report-title {
         color: $white;
         font-weight: 600;
         float: left;
         text-transform: uppercase;
         padding: 15px 25px;

         i {
            @include font-size(14px);
            margin-right: 10px;
         }
      }

      .report-total-container {
         float: right;
         overflow: hidden;
         padding: 15px 25px;
      }

      .report-total-text {
         color: $white;
         float: left;
         margin-right: 10px;
      }

      #report-total {
         @include font-size(11px);
         @include line-height(11);
         font-weight: 600;
         background: #FF0800;
         color: $white;
         float: right;
         line-height: 0;
         padding: 11px 0 0 0 !important;
         margin-top: 2px;
         text-align: center;
         height: 20px;
         width: 20px;
         -webkit-border-radius: 10px;
         -moz-border-radius: 10px;
         border-radius: 10px;
      }

      #reset-container {
         float: right;
         padding: 0;
         margin: 0;

         div {
            float: left;
         }

         a {
            display: block;
            color: $white;
         }

         .view-custom-report {
            padding: 13px 25px;
            border-left: 1px solid $grey-medium;

            a {
               background: $link;
               padding: 2px 18px;
               -webkit-border-radius: 2px;
               -moz-border-radius: 2px;
               border-radius: 2px;

               &:hover {
                  background: lighten($link, 5%);
               }
            }
         }

         .reset {
            border-left: 1px solid $grey-medium;
            padding: 15px 20px;
            i {
               @include font-size(14px);
               color: $white;
               margin-right: 10px;
            }
            a {
               color: lighten($grey-medium, 20%);

               &:hover {
                  color: $white;
               }
            }
         }
      }
   }
}

.page,
.news,
.book,
.blog,
.eds_properties,
.report_container_mobile {
   display: none;
}
