.view-food-industry-view,
.view-machine-building-industry-view,
.view-light-industry-view,
.view-furniture-industry-view,
.view-construction-industry-view,
.view-agriculture-industry-view,
.view-woodworking-industry-view,
.view-chemical-industry-view,
.view-mining-industry-view {

  .isotope-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 50px;

    li {
      margin-right: 15px;
      margin-bottom: 15px;

      a {
        font-size: 16px;
        color: #13b8e5;

        .views-field {
          padding: 5px 15px;
          border-radius: 5px;
          border: 1px solid #13b8e5;
        }

        &:hover {
          color: #001549;
          .views-field-field-term {
            border-color: #001549;
          }
        }
      }

      .selected {

        .views-field {
          border-color: #001549;
          background-color: #001549 !important;
        }
      }
    }

    .first {
      a {
        padding: 5px 15px;
        border-radius: 5px;
        border: 1px solid #13b8e5;
        color: #13b8e5;
      }

      .selected {
        border-color: #001549;
        background-color: #001549 !important;
      }
    }
  }

  .isotope-element {
    width: 20%;
    margin-right: 20px;
    margin-bottom: 30px;

    @media only screen and (max-width: 767px)  {
      width: 25%;
    }

    @media only screen and (max-width: 479px)  {
      width: 40%;
    }

    @media only screen and (max-width: 350px)  {
      width: 100%;
    }
  }

  .industry-item-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .industry-item-img {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;

      .industry-item-title,
      img {
        margin-left: auto;
        margin-right: auto;
      }

      img {
        height: 90px !important;
        width: auto;
        margin-bottom: 15px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .industry-item-description {
      display: none;
    }
  }
}