.node-type-blog {

  .rrssb-item-list {
    padding-bottom: 10px;
  }

  .field-name-field-blog-image {
    margin-bottom: 20px;
  }

  .blog-title {
    padding-top: 0;
  }

  .author-date {
    @include font-size(12px);

    span, div {
      display: inline;
    }

    .line-sep {
      @include font-size(14px);
      margin: 0 2px;
    }
  }
}
