// Menu nav top.

.navigation-top {
  background: $white;
  border-bottom: 1px solid #eee;

  .region {
    @extend .container;
    height: 40px;
  }
}
