// Styles for miscellaneous blocks.

.sidebar {

  .block {
    margin-bottom: 40px;
    @include font-size(14px);
    @include line-height(14);

  }

  .block-title {
    @include font-size(16px);
    text-transform: uppercase;
  }

}

#block-block-16, #block-block-24 {
  margin-top: 35px;
  p {
    text-align: center;
  }
}

#block-views-map-on-main-block {
  width: 480px;
  display: inline-block;
  border: 1px solid;
  margin-left: 10px;
  position: relative;
  .block-banner-image a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    text-indent: -9999px;
    overflow: hidden;
  }
  .views-field-nothing {
    position: absolute;
    display: block;
    width: 100%;
    height: 50px;
    bottom: 0;
    right: 0;
    padding: 5px 25px;
    color: #fff !important;
    background-color: rgba(0, 0, 0, 0.8);
    .field-content {
      .banner-headline h2 {
        font-size: 29px;
        color: #fff;
        font-weight: normal;
      }
      .banner-description {
        margin-bottom: 15px;
        float: left;
        font-size: 1em;
        overflow: hidden;
        line-height: 21px;
        font-weight: 100;
        width: 380px;
      }
      .banner-link {
        .banner-read-more {
          background-image: url(/sites/all/themes/astrum/images/ico_read_more.png);
          width: 27px;
          height: 27px;
          display: block;
          float: right;
        }
        .banner-read-more:hover {
          opacity: 0.6;
        }

      }
    }
  }
}

#block-block-26,
#block-block-28 {
  width: 479px;
  height: 230px;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  .block-catalog-image {
    width: 153px;
    height: 230px;
    display: inline-block;
    position: relative;
    margin-right: 6px;
    .catalog-main-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      overflow: hidden;
      .first-catalog {
        color: #fff;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        margin: 50px 5px 15px 5px;
        display: block;
      }
      .description-catalog {
        color: #fff;
        font-weight: 100;
        font-size: 12px;
        line-height: 21px;
        display: block;
        margin: 5px;
      }
      .banner-read-more {
        background-image: url(/sites/all/themes/astrum/images/ico_read_more.png);
        width: 27px;
        height: 27px;
        display: block;
        float: right;
        margin-right: 10px;
        margin-top: 10px;
      }
    }
  }
}
#block-block-26 .block-catalog-image:nth-child(3),
#block-block-28 .block-catalog-image:nth-child(3) {
  margin-right: 0;
}
.dark-blue-background {
  background-color: #2d6ba6;

}
.dark-green-background {
  background-color: #7da004;
}
.dark-orange-background {
  background-color: #e3a921;
}