.copyright-container {
  background: #fff;
  border-top: 1px solid #d9d9d9;
  height: 76px;
  #block-block-1 {
    color: #959595;
    font: 400 14px "Myriad Pro", sans-serif;
    line-height: 22px;
    margin: 27px 0;
    p {
      margin: 0;
    }
    a {
      color: #959595;
      &:hover {
        color: $link;
      }
    }
  }
}
