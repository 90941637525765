.front-features-four {
  background: #f3f3f4;
  padding-top: 0;
  padding-bottom: 100px;
  .region {
    padding: 0;
  }
  .content {
    padding: 0 5px;
  }
  .block-content-container {
    .content {
      @include font-size(14px);
      @include line-height(11px);
      .view-content {
        @include display-flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .views-row {
          position: relative;
          -webkit-box-flex: 1;
          -ms-flex: 1 0 25%;
          flex: 1 0 25%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 15px;
          //min-width: 270px;
          .views-field-field-background-image-box-1 {
            margin-bottom: 20px;
            .field-content {
              img {
                width: 100%;
                max-width: 245px;
              }
            }
          }
          .views-field-field-title-actual {
            .field-content {
              color: #000;
              font: 400 30px 'Myriad Pro', sans-serif;
              margin-bottom: 20px;
              text-transform: uppercase;
            }
          }
          .views-field-field-description-box {
            .field-content {
              color: #000;
              font: 400 14px 'Myriad Pro', sans-serif;
              line-height: 20px;
              margin-bottom: 31px;
              padding-bottom: 27px;
            }
          }
          .views-field-field-link-box {
            background: #000;
            position: absolute;
            right: 15px;
            bottom: 0;
            left: 15px;
            text-align: right;
            .field-content {
              a {
                color: #fff;
                display: block;
                font: 600 14px 'Myriad Pro', sans-serif;
                padding: 8px 24px 8px 0;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
