.banner-internal {
  height: 319px; /* 225px; */
  overflow: hidden;
}

.banner-internal #page-title {
  font-size: 41px; /* 56px; */
  font-weight: 700;
  line-height: 50px;
  /* line-height: 3.75rem; */
  color: #ffffff;
  text-align: center;
  padding: 83px 20px;
}

.page-jobs .banner-internal,
.node-type-jobs .banner-internal,
.page-taxonomy .banner-internal {
  height: 225px;
}