#block-breadcrumb.breadcrumb {
  text-align: left;
  width: 100%;
  #breadcrumbs ul {
    float: none;
    li:nth-child(1) {
      display: none;
    }
  }
}

#page-title.page-title {
  width: 100%;
}

#node-262 table tr td,
#node-263 table tr td {
  border-bottom: 1px solid #999;
  border-left: none;
  border-top: none;
  text-align: center;
  border-right: 5px solid #ffffff;
  img {
    margin: 0 auto;
  }
}

#node-264 table tr td a img {
  margin: 0 auto;
}

.video-page {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  text-align: center;

  iframe,
  object,
  embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid #aaaaaa;
  }
}

#block-system-main {
  .showworks {
    ul {
      li {
        width: 377px;
        margin-right: 20px;
        margin-bottom: 20px;
        .portfolio-item {
          .item-description {
            padding: 20px 20px 10px 20px;
            span {
              display: none;
              color: #ffffff;
            }
            h5 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

#block-system-main .showworks ul li:nth-child(3n) {
  margin-right: 0;
}

#block-system-main .showworks ul li:hover .portfolio-item .item-description span {
  display: block;
}

.events-header {
  display: block;
  width: 100%;
  border-bottom: 2px solid #a0a0a0;
  .events-header-date {
    float: left;
    width: 90px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
  .events-header-text {
    width: auto;
    display: block;
    float: left;
    margin-left: 85px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
  .views-exposed-form .views-exposed-widgets {

    .views-exposed-widget {
      padding-top: 2px;
      margin-left: 50px;
      #edit-field-page-category-tid {

        .form-type-checkbox {
          display: inline-block;

          .option {
            display: inline-block;
            color: #000;
            font-size: 16px;
            margin-right: 20px;
            margin-bottom: 0;
            line-height: 20px;
          }
        }
      }
      .form-submit {
        margin-top: 0;
        border-radius: 0;
      }
    }
    .views-submit-button {
      margin-left: 0;
      border: none;
    }
  }
}

.events-list {
  ul {
    li {
      border-bottom: 1px solid #aaaaaa;
      display: inline-block;
      width: 100%;
      margin-top: 25px;
      padding-bottom: 20px;
      .views-field-field-event-date {
        width: 120px;
        float: left;
        display: inline-block;
        .date-display-range {
          color: #000000;
          font-size: 14px;
          font-weight: 700;
          .date-display-start {
            display: block;
            color: #000000;
            font-size: 14px;
            font-weight: 700;
          }
          .date-display-end {
            display: block;
            color: #000000;
            font-size: 14px;
            font-weight: 700;
          }
        }
        .date-display-single {
          display: block;
          color: #000000;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .views-field-title {
        margin-left: 175px;
        text-transform: uppercase;
        color: #000000;
         a {
          text-transform: uppercase;
          color: #000000;
          text-decoration: underline;
          font: 700 18px "miriad-regular";
        }
      }
      .views-field-field-venue {
        margin-left: 175px;
        margin-top: 10px;
        margin-bottom: -5px;
      }
      .views-field-body {
        margin-left: 175px;
        font-weight: 400;
      }
      .views-field-field-organizer {
        margin-left: 175px;
      }
    }
  }
}

#node-270 {
  .field-item {
    p {
      padding-bottom: 14px;
      margin: 0;
      font-size: 17px;
      line-height: 28px;
      color: #4a4a4a;
    }
    h5 {
      padding-bottom: 14px;
      margin: 0;
      font-size: 17px;
      line-height: 28px;
      color: #4a4a4a;
      font-weight: 700;
    }
    a {
      font-weight: 700;
      color: #132193;
      text-decoration: none;
      transition: all 300ms;
      margin: 0 0 10px 30px;
      display: block;
      font-size: 17px;
      line-height: 28px;
    }
    div {
      display: block;
      column-count: 2;
      a {
        font-weight: 700;
        color: #132193;
        text-decoration: none;
        transition: all 300ms;
        margin: 0 0 10px 30px;
        display: block;
        font-size: 17px;
        line-height: 28px;
      }
    }
  }
}

#node-270 .field-item div a:hover {
  color: #2ab2c6;
}

#node-270 .field-item a:hover {
  color: #2ab2c6;
}

.post {
  margin-top: -20px;
  ul {
    padding-left: 20px;
    list-style-type: disc;
    li {
      padding-top: 5px;
      margin-left: 20px;
    }
  }

  .rrssb-buttons {
    li {
      margin-left: 0;
    }
  }
  p {
    span {
      img {
        display: inline-block;
      }
    }
  }
  table {
    tr {
      td {
        border: 1px solid #aaaaaa;
      }
    }
  }
}
.strategy-of-development-block {
  width: 300px;
  text-align: center;
  a {
    .strategy-of-development-bg {
      background: url(/sites/all/themes/astrum/images/pages/strategia.png) no-repeat;
      width: 180px;
      height: 180px;
      display: block;
      margin: 0 auto;
    }
  }
}

/*.form-item-property-search-property-type {
  .property_type {
    display: none;
  }
}*/
.form-item-property-search-sort-by {
  display: none;
}
.form-item-property-search-sort-type {
  display: none;
}

.page-search {
  .post {
    margin-top: 20px;
  }

  .content {
    #search-form {
      margin-top: -30px;
      margin-bottom: 20px;
    }
  }
}

.statistic-information-content,
#node-379,
#node-385,
#node-391,
#node-399 {

  .tables-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;

    @media only screen and (max-width: 479px)  {
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column;
    }
  }

  strong {
    color: unset;
  }

  table {
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 959px)  {
      width: 100% !important;
    }
  }
}

#node-391 {
  .tables-wrapper {
    @media only screen and (max-width: 959px)  {
      -webkit-box-orient:vertical;
      -webkit-box-direction:normal;
      -ms-flex-direction:column;
      flex-direction:column;
    }
  }
 }

 #node-327,
 #node-326{
  img {
    display: inline-block;
    width: 350px !important;
    height: auto;

    &:first-of-type {
      margin-right: 30px;
    }

    @media only screen and (max-width: 767px)  {
      width: 400px !important;
    }
  }


}

.node-type-eds-properties {
  .field-name-field-property-photographs {
    img {
      margin-bottom: 20px;
    }
  }
}