.page-story-of-success {
  .view-story-of-succes {

    .views-row {
      padding-bottom: 20px;
      margin-bottom: 50px;
      border-bottom: 1px solid #d4d4d4;

      &:last-child {
        border-bottom: none;
      }
    }

    .story-wrapper {
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;

      @media only screen and (max-width: 767px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .story-img {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 43%;
        flex: 0 0 43%;

        @media only screen and (max-width: 1199px) {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
        }
      }

      .story-title {
        a {
          color: #000;
        }
      }

      .story-info {
        @media only screen and (max-width: 1199px) {
          margin-left: 50px;
        }

        @media only screen and (max-width: 767px) {
          margin-left: 0;
          margin-top: 30px;
        }
      }

      .story-date {
        font-size: 0.75rem;
        font-style: italic;
      }
    }
  }
}