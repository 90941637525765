// Main nav option 2. Horizontal inside the header.
.navigation-main-1 {
  position: absolute;
  right: 20px;
  top: 81px;
  // Horizontal menu
  .menu {

    > li {
      padding: 0;
      margin: 0;

      > a {
        @include font-size(14px);
        font-family: "myriad-pro", sans-serif;
        color: $grey-dark;
        font-weight: 700;
        margin-left: 16px;
        text-transform: uppercase;
        -webkit-transition: color ease-in-out .2s;
        -moz-transition: color ease-in-out .2s;
        -o-transition: color ease-in-out .2s;
        transition: color ease-in-out .2s;

        &:hover,
        &.active {
          color: $link;
        }
      }

      &.sfHover {
        > a {
          color: $link;
        }
      }
    }

    li.sfHover {

      > ul {
        top: 50px; // control the placement of dropdown
        border-radius: 0px 0px 5px 5px;
        -moz-border-radius: 0px 0px 5px 5px;
        -webkit-border-radius: 0px 0px 5px 5px;
        border: 0px solid #000000;
        -webkit-box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.25);
      }

      > a::after { // triangle pointing up to parent menu item
        content: ' ';
        display: block;
        position: absolute;
        bottom: -36px;
        left: 50%;
        margin-left: -15px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 16px solid black; // color of the triangle
      }
    }

    li.sf-no-children {
      > a::after {
        display: none;
      }
    }
  }

  // First dropdown
  .menu ul {
    background: $black;
    overflow: visible !important;

    > li {
      position: relative;
      padding: 0;
      margin: 0;

      &.sfHover {
        a::after {
          display: none;
        }

        > ul {
          top: 0 !important;
        }
      }

      i {
        @include font-size(20px);
        color: $grey-light;
        position: absolute;
        top: 50%;
        right: 4%;
        z-index: 300;
        margin-top: -10px;
      }
    }

    a {
      @include font-size(14px);
      color: #fff;
      padding: 10px 30px 10px 20px;
      margin: 0;
      text-transform: none;
      font-weight: 400;
      border-bottom: 1px solid #484848;
      -webkit-transition: background-color ease-in-out .2s;
      -moz-transition: background-color ease-in-out .2s;
      -o-transition: background-color ease-in-out .2s;
      transition: background-color ease-in-out .2s;

      &:hover {
        background: #333;
      }
    }
  }

  // Second dropdown
  .menu ul ul {
    background: #292929;
  }

  // Third dropdown
  .menu ul ul ul {
    background: #292929;
  }
}

.navigation-main-1 .menu ul li.last a,
.navigation-main-1 .menu ul li.firstandlast a {
  border-bottom: 0;
}

.navigation-main-1 .menu ul li.last a:hover,
.navigation-main-1 .menu ul li.firstandlast a:hover {
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
}

body .header-container .navigation-main-1 .menu ul li ul li a:hover {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

body .header-container .navigation-main-1 .menu ul li ul li.last a:hover,
body .header-container .navigation-main-1 .menu ul li ul li.firstandlast a:hover {
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
}