.region-sidebar-first {
  .block-menu-block {
    background: $grey-light;
    padding: 10px 25px;
  }
  .menu {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      list-style-image: none;

      a {
        display: block;
        color: $grey-dark;
        padding: 3px 30px 3px 0;
        position: relative;
        -webkit-transition: background-color ease-in-out .2s;
        -moz-transition: background-color ease-in-out .2s;
        -o-transition: background-color ease-in-out .2s;
        transition: background-color ease-in-out .2s;

        &:hover {
          color: $link;
        }

        &.active {
          color: $link;
          background-color: lighten($grey-medium, 60%);
          border-right: 3px solid $link;
        }
      }
      &.last:not(.expanded) a {
        border-bottom: none;
      }
    }

    i {
      @include font-size(20px);
      color: $grey-dark;
      position: absolute;
      top: 50%;
      right: 4%;
      z-index: 300;
      margin-top: -12px;
    }
  }

  // Tier one
  .menu-block-wrapper,
  .block-menu .content {
    > ul {
      > li {
        > a {
          @include font-size(16px);
          border-bottom: 1px solid lighten($grey-medium, 50%);
        }
      }
    }
  }

  // Tier two
  .menu {
    ul {
      li {
        padding-left: 10px;
      }
      a {
        @include font-size(14px);
        border-bottom: 1px solid lighten($grey-medium, 50%);
      }
    }
  }

  // Tier Three
  .menu ul {
    ul {
      li {
        padding-left: 10px;
      }
      a {
        @include font-size(12px);
        border-bottom: 1px solid lighten($grey-medium, 50%);
      }
    }
  }
}

.logged-in {
  #block-menu-menu-jobs-menu {
    .menu li.first {
      display: none;
    }
  }
}
