.page-news #block-system-help {
  display: none;
}

.page-news .main {
  padding-top: 0;
}

.page-news .view-news .views-field-title h2 {
  padding-top: 0;
}

.page-news .view-news .views-field-title a {
  color: #333333;
}

.page-news .view-news .views-field-title a:hover {
  color: $link;
}

.page-news .view-news .views-field-field-news-image {
  margin-bottom: 20px;
}

.page-news .view-news .views-field-field-news-authors,
.page-news .view-news .views-field-created {
  font-size: 12px;
  font-size: 0.75rem;
  color: #888888;
}

.page-news .view-news .views-field-nothing {
  display: block;
  margin-bottom: 10px;
}

.page-news .view-news .line-sep {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 2px;
}

.page-news .view-news .views-row {
  padding-bottom: 20px;
  margin-bottom: 50px;
  border-bottom: 1px solid #d4d4d4;
}

.page-news .view-news .views-row-last {
  padding-bottom: 0;
  border-bottom: none;
}