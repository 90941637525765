#block-menu-block-3 ul.menu {
  margin: 0;
  padding: 0;
}
#block-menu-block-3 ul.menu li {
  line-height: 13px;
  line-height: 1.625rem;
  list-style: none;
  list-style-image: none;
  padding: 0 20px 0 0;
  margin: 0;
  float: left;
  width: 50%;
}
#block-menu-block-3 ul.menu li a {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 10px;
  line-height: 1.25rem;
}