.page-markets {

  .view-markets {

    .view-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      @media only screen and (max-width: 960px)  {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .views-row {
        @media only screen and (max-width: 960px)  {
          margin-bottom: 50px;
        }
      }
    }
  }

  .market-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .market-info {
      margin-left: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .market-post-date {
        font-size: 12px;
        font-style: italic;
      }

      .market-link {
        font-size: 20px;
        margin-top: 25px;
      }
    }

    .market-img {
      img {
        height: 275px !important;
        width: auto;
      }
    }
  }
}