#block-views-social-media-icons-block,
#block-views-social-media-icons-block-1 {
  .views-row {
    @include display-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .views-field {
    margin-left: 7px;
    a {
      @include font-size(12px);
      color: #000;
      border: 1px solid #000;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      height: 23px;
      width: 23px;
      text-align: center;
      display: block;
      &:hover {
        background: $link;
        border: 1px solid $link;
        color: #fff;
        i {
          color: #ffffff;
        }
      }
      i {
        @include font-size(12px);
        display: block;
        margin-top: 5px;
      }
    }
  }
}

//#block-views-social-media-icons-block {
//  float: right;
//  margin-left: 25px;
//  margin-right: 230px;
//  margin-top: 7px;
//}
