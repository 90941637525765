#block-views-hover-reveal-block {
  .views-row {
    background-position: center center;
    float: left;
    width: 25%;
    position: relative;
    overflow: hidden;
    &.views-row-first {
      width: 50%;
    }
    .views-field-field-background-image {
      height: 318px;
      width: 100%;
    }
    .views-field-nothing {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      width: 100%;
      padding: 21px 30px 0;
      margin: 0;
      color: #fff;
      -webkit-transform: translate3d(0, 40%, 0);
      -moz-transform: translate3d(0, 40%, 0);
      -ms-transform: translate3d(0, 40%, 0);
      -o-transform: translate3d(0, 40%, 0);
      transform: translate3d(0, 40%, 0);

      -webkit-transition: transform .4s;
      -moz-transition: transform .4s;
      -ms-transition: transform .4s;
      -o-transition: transform .4s;
      transition: transform .4s;
      .hover-reveal-content {
        .hover-reveal-headline {
          @include font-size(14px);
          color: #fff;
          border-bottom: 1px solid #ffffff;
          font-family: 'Myriad Pro', 'Helvetica', Arial, sans-serif;
          font-weight: 700;
          line-height: 20px;
          padding-bottom: 30px;
          margin-bottom: 30px;
          text-transform: uppercase;
        }
        .hover-reveal-description {
          color: $white;
          font-family: 'Myriad Pro', Helvetica, Arial, sans-serif;
          font-size: 16px;
          line-height: 20px;
        }
        .hover-reveal-link {
          a {
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
    &:hover {
      .views-field-nothing {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

body.front .front-features-six #block-block-18 #map {
  width: 100%;
  height: 319px;
}