$theme-active-links: #1a77bd;
$white: #ffffff;
$black: #000000;
$red: #FF0800;
$grey-light: #eeeeee;
$grey-medium: #555555;
$grey-dark: #333333;

$link: #1a77bd;

$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';
